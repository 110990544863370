import { getWeekDay, parseISO8601Duration } from '~/lib/utilities/datetime'
import { OpeningTimes, RegularTime } from '~/types/store'

const EmptyDateString = '0001-01-01T00:00:00.0000000'

export const convertRegularTime = (data: Record<string, any>): RegularTime => {
  return {
    day: data.Day ? getWeekDay(data.Day) : -1,
    openTime: data.OpeningTime
      ? parseISO8601Duration(data.OpeningTime)
      : undefined,
    closeTime: data.ClosingTime
      ? parseISO8601Duration(data.ClosingTime)
      : undefined,
  }
}

export const convertRegularTimes = (
  data: Record<string, any>[]
): RegularTime[] => {
  return data.map((regularTime) => convertRegularTime(regularTime))
}

export const convertOpeningTimes = (
  data: Record<string, any>
): OpeningTimes => {
  return {
    regularTimes: convertRegularTimes(data.Weekdays),
    extraOpeningDays: data.ExtraOpeningDays,
    extraClosingDays: data.ExtraClosingDays,
  }
}

export const convertAsposRangeDate = (str?: string) => {
  if (!str) return undefined

  if (str === EmptyDateString) return undefined

  const utcDate = new Date(str)
  const localDate = new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate()
  )

  return localDate
}