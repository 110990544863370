import { ref, useContext } from '@nuxtjs/composition-api'
import { dropLastWhile } from 'ramda'
import { useCart } from './useCart'
import { useLoading } from './ui/useLoading'
import { CartLineNote } from '@/types/cart'

const cartLineNotes = ref<CartLineNote[] | null>(null)

export const useCartLineNote = () => {
  const changeCartLineNote = (note: CartLineNote) => {
    const lines = cartLineNotes.value ?? []
    const line = lines?.find(
      (line) => line.productId === note.productId
    )
    if (line) {
      line.notes = note.notes
    } else {
      lines?.push(note)
    }
    cartLineNotes.value = lines
  }

  const deleteCartLineNote = (productId: number) => {
    const line = cartLineNotes.value?.find(
      (line) => line.productId === productId
    )
    if (line && cartLineNotes.value) {
      cartLineNotes.value = dropLastWhile((x) => x.productId === productId, cartLineNotes.value)
    }
  }

  const submitCartLineNotesLoading = useLoading()
  const { app } = useContext()

  const { setCart } = useCart()
  const submitCartLineNotes = () => {
    return submitCartLineNotesLoading.scope(async () => {
      if (!cartLineNotes.value?.length) return
      const newCart = await app.$api.cart.setCartLineNotes(cartLineNotes.value)
      setCart(newCart)
    })
  }

  return {
    cartLineNotes,
    submitCartLineNotesLoading: submitCartLineNotesLoading.value,

    changeCartLineNote,
    deleteCartLineNote,
    submitCartLineNotes,
  }
}
