var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"discount-label inline-flex justify-center items-center max-w-full cursor-default",class:{
    'text-xs px-2 py-1': _vm.size === 'xs',
    'text-sm px-3 py-1': _vm.size === 'sm',
    'text-base px-3 py-1.5': _vm.size === 'md',
    'text-lg px-3 py-1': _vm.size === 'lg',
  },style:({
    backgroundColor: _vm.backgroundColor,
  }),attrs:{"title":_vm.labelText}},[_c('span',{staticClass:"uppercase font-thin leading-none truncate",style:({
      color: _vm.textColor,
    })},[_vm._v("\n    "+_vm._s(_vm.labelText)+"\n  ")])])}
var staticRenderFns = []

export { render, staticRenderFns }