import Vue from 'vue'
import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import VueLazyload from 'vue-lazyload'

export default defineNuxtPlugin(() => {
  Vue.use(VueLazyload, {
    preLoad: 1.3,
    error: '/images/404.png',
    loading: '/images/loading.svg',
    dispatchEvent: true
  })
})
