import { defineNuxtPlugin } from '@nuxtjs/composition-api'
import { useEvents } from '~/composables/framework/useEvents'
import { useDatalayer } from '~/composables/useDatalayer.ga4'

import { ProductEvents } from '~/composables/product/useProduct'
import { CartEvents } from '~/composables/useCart'
import {
  SearchEventArgs,
  ProductEventArgs,
  ProductListEventArgs
} from '~/composables/product/useProduct.e'
import {
  CartEventArgs,
  CartLinesEventArgs,
  SetPaymentEventArgs,
  OrderEventArgs
} from '~/composables/useCart.e'

export default defineNuxtPlugin(({ $gtm }) => {
  const { on } = useEvents()
  const {
    onSearch,
    onViewProductList,
    onClickProduct,
    onViewProductDetails,

    onAddProducts,
    onRemoveProducts,
    onViewCart,

    onBeginCheckout,
    onSetShipment,
    onSetPayment,
    onPaid
  } = useDatalayer($gtm)

  // Product
  on(ProductEvents.Search, (e: SearchEventArgs) =>
    onSearch(e.term)
  )
  on(ProductEvents.ViewList, (e: ProductListEventArgs) =>
    onViewProductList(e.products, e.webNode)
  )
  on(ProductEvents.Click, (e: ProductEventArgs) =>
    onClickProduct(e.product, e.index)
  )
  on(ProductEvents.ViewDetails, (e: ProductEventArgs) => {
    onViewProductDetails(e.product)
  })

  // Cart
  on(CartEvents.AddProducts, (e: CartLinesEventArgs) =>
    onAddProducts(e.lines)
  )
  on(CartEvents.RemoveProducts, (e: CartLinesEventArgs) =>
    onRemoveProducts(e.lines)
  )
  on(CartEvents.View, (e: CartEventArgs) =>
    onViewCart(e.cart)
  )

  // Checkout
  on(CartEvents.Checkout, (e: CartEventArgs) =>
    onBeginCheckout(e.cart)
  )
  on(CartEvents.SetShipment, (e: CartEventArgs) =>
    onSetShipment(e.cart)
  )
  on(CartEvents.SetPayment, (e: SetPaymentEventArgs) =>
    onSetPayment(e.cart, e.payment)
  )
  on(CartEvents.Paid, (e: OrderEventArgs) =>
    onPaid(e.order)
  )
})
