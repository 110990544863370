import { ref, useContext } from '@nuxtjs/composition-api'
import { getNextYearDate } from '~/lib/utilities/datetime'
import { PermissionStatus, CookiePreference } from '~/types/common'

const COOKIE_PERMISSION_KEY = 'beautyx_cookieconsent_status'

export function useCookiePermission() {
  const { $storage } = useContext()

  const visible = ref(false)

  const setCookie = (control: PermissionStatus | CookiePreference) => {
    // clean prev cookie value first
    $storage.removeCookie(COOKIE_PERMISSION_KEY)
    $storage.setCookie(COOKIE_PERMISSION_KEY, control, {
      path: '/',
      expires: getNextYearDate(),
    })
  }

  const getCookie = (): PermissionStatus | CookiePreference => {
    return $storage.getCookie(COOKIE_PERMISSION_KEY) || PermissionStatus.Prompt
  }

  const open = () => {
    visible.value = true
  }

  const close = () => {
    visible.value = false
  }

  const onAccept = () => {
    setCookie(PermissionStatus.Granted)
    close()
  }

  const onRefuse = () => {
    setCookie(PermissionStatus.Denied)
    close()
  }

  const onSettingPreference = (preference: CookiePreference) => {
    setCookie(preference)
    close()
  }

  return {
    visible,
    getCookie,
    open,
    close,
    onAccept,
    onRefuse,
    onSettingPreference,
  }
}
