import { convertStore } from './store'
import { convertAddress } from './geo'
import { decodeHtml } from '~/lib/utilities'
import { Customer, CustomerAddress } from '@/types/customer'
import project from '~/project.config.js'

const AVAILABLE_STORE_EXCLUDE_IDS = new Set(
  project.store?.availableStoreExcludeIds ?? []
)

export const convertCustomer = (data: any): Customer => {
  const defaultStoreId = !AVAILABLE_STORE_EXCLUDE_IDS.has(
    data.DefaultStoreId as number
  )
    ? data.DefaultStoreId
    : undefined
  const customer: Customer = {
    id: data.Id,
    email: data.Email,
    firstName: data.FirstName,
    middleName: data.MiddleName,
    mobileNumber: data.MobileNumber,
    phoneNumber: data.PhoneNumber,
    defaultStoreId,
    defaultStore:
      defaultStoreId && data.DefaultStore
        ? convertStore(data.DefaultStore)
        : undefined,
    gender: data.Gender,
    lastName: data.LastName,
    isOptIn: data.IsOptIn,
    addresses: data.Addresses?.length
      ? convertCustomerAddresses(data.Addresses)
      : undefined,

    kvkNumber: data.CoCNumber,
    companyName: data.CompanyName,
    creditLimit: data.FinancialData?.CreditLimit,
    creditBalance: data.FinancialData?.CreditBalance,
    languageCode: data.LanguageCode,
    totalBonusPoints: data.TotalBonusPoints || 0,
    priceListId: data.PriceListId,
  }

  if (data.Contacts) {
    const contact = data.Contacts?.[0]
    if (contact) {
      customer.allowOnAccount = contact.AllowOnAccount ?? false
      customer.bonusPoints = contact.DefaultCard?.TotalBonusPoints ?? 0
      customer.contactId = contact.Id
      customer.mobileNumber = contact.MobileNumber
      customer.phoneNumber = contact.PhoneNumber
      if (contact.Address) {
        customer.contactAddress = convertCustomerAddress(contact.Address)
      }
    }
  }

  if (data.CustomerGroup) {
    customer.groupCode = data.CustomerGroup.Code
  }

  return customer
}

export const convertCustomerAddress = (data: any): CustomerAddress => {
  const convertedAddress = convertAddress(data)
  return {
    id: data.Id,
    customerId: data.CustomerId,
    type: data.Type,
    name: data.Name,
    firstName: data.FirstName,
    middleName: data.MiddleName,
    lastName: data.LastName,
    gender: data.Gender,
    phoneNumber: data.PhoneNumber,
    emailAddress: data.EmailAddress,
    memo: data.Memo ? decodeHtml(data.Memo) : '',
    ...convertedAddress,
  }
}

export const convertCustomerAddresses = (data: any[]): CustomerAddress[] => {
  return data.map((item) => convertCustomerAddress(item))
}
