import { clamp, Dictionary } from 'ramda'
import { convertVariantProductsJson } from './product.meili'
import { convertFullDateTime } from './common'
import { convertBrand } from '~/lib/api/deserializers/brand'
import { convertWebNode } from '~/lib/api/deserializers/webNode'
import { getValidColorHex, trimString, decodeHtml } from '~/lib/utilities'
import { inRange, Quarter, monthsNL, monthsEN } from '~/lib/utilities/datetime'
import {
  Product,
  ProductDiscount,
  ProductReview,
  // ProductDeliverySize,
  // ProductDeliveryType,
  ProductDiscountLevel,
  ProductSelection,
  ProductLabel,
  ProductAvailabilityInServer,
  ProductStock,
  WebshopBannerType,
  WebshopBanner,
} from '~/types/product'
import project from '~/project.config.js'

interface GetValueOption<T> {
  name: string
  convertValue: (value: string) => T
  defaultValue: T
}

export const VIEWABLE_EXCLUDE_TYPES = new Set(
  project.product?.viewableExcludeTypes ?? []
)

const TAG_FIELD_KEYS = [
  'Geslacht',
  'Subgroep',
  'Geurtype',
  'Textuur',
  'Huidtype',
  'Huidbehoefte',
  'Huidzorg',
  'Geschikt_voor',
  'Effect',
] as const

export const checkOrderable = (product: Product) => {
  if (product.isVirtual || !product.isViewable) return false

  return product.isOrderable && product.inStock
}

export const checkValidInCart = (
  product: Product,
  considerWebNode: boolean = true
) => {
  if (product.isVirtual) return false
  if (considerWebNode && !product.webNode) return false

  return (
    product.isOrderable && (product.maxStockEnabled ? product.inStock : true)
  )
}

export const getMaxQuantity = (stockQuantity: number, maxInCart?: number) => {
  return maxInCart !== undefined
    ? clamp(0, stockQuantity, maxInCart)
    : stockQuantity
}

export const convertDiscount = (data: any): ProductDiscount => {
  const normalizedData = {
    Id: data.Id || data.id,
    DiscountCategory: data.DiscountCategory || data.discountCategory,
    TicketDescription: data.TicketDescription || data.ticketDescription,
    Description: data.Description || data.description,
    Images:
      data.Images ||
      data.images?.map((item: any) => ({
        Id: item.id,
        Type: item.type,
        Url: item.url,
      })),
    StartDate: data.StartDate || data.startDate,
    StartTime: data.StartTime || data.startTime,
    EndDate: data.EndDate || data.endDate,
    EndTime: data.EndTime || data.endTime,
    PriceList:
      data.PriceList ||
      (data.priceList && {
        Id: data.priceList.id,
        Description: data.priceList.description,
      }),
    PriceListId: data.PriceListId || data.priceListId,
  }

  return {
    id: normalizedData.Id,
    name: normalizedData.TicketDescription || normalizedData.Description,
    category: normalizedData.DiscountCategory?.toLowerCase(),
    img: normalizedData.Images?.[0]?.Url,
    startDate: normalizedData.StartDate
      ? convertFullDateTime(normalizedData.StartDate, normalizedData.StartTime)
      : undefined,
    endDate: normalizedData.EndDate
      ? convertFullDateTime(
          normalizedData.EndDate,
          normalizedData.EndTime,
          true
        )
      : undefined,
    priceListId: normalizedData.PriceListId || normalizedData.PriceList?.Id,
  }
}

export const convertDiscounts = (discounts: any): ProductDiscount[] => {
  return (
    discounts?.reduce(
      (prev: ProductDiscount[], current: Record<string, any>) => {
        const discount = convertDiscount(current)
        const webshopLabelTypeCode =
          current.WebshopLabelTypeCode || current.webshopLabelTypeCode
        if (
          discount.name &&
          webshopLabelTypeCode !== 'NOLABEL' &&
          inRange(discount.startDate, discount.endDate, true)
        ) {
          prev.push(discount)
        }
        return prev
      },
      []
    ) ?? []
  )
}

export const convertMoreLess = (
  product: Product,
  moreLessLevels: Array<any>
) => {
  if (!moreLessLevels) return

  product.moreLessLevels = moreLessLevels
    .map(
      (data) =>
        ({
          id: data.Id,
          quantity: data.Quantity,
          priceInclTax: data.DiscountPriceInclTax,
          priceExclTax: data.DiscountPriceExclTax,
          save:
            (product.priceInclTax - data.DiscountPriceInclTax) /
            product.priceInclTax,
        } as ProductDiscountLevel)
    )
    .sort((a: ProductDiscountLevel, b: ProductDiscountLevel) =>
      (a.quantity ?? 0) < (b.quantity ?? 0) ? -1 : 1
    )
}

export const convertStock = (data: any) => {
  return {
    storeId: data.StoreId,
    quantity: data.AvailableQuantity > 0 ? data.AvailableQuantity : 0,
  }
}

export const convertRating = (data: any) => {
  return {
    count: data?.Count ?? 0,
    rating: data?.Rating,
  }
}

export const convertRatings = (data: any) => {
  return data
    .filter((item) => item?.Rating)
    .map((ratingItem) => convertRating(ratingItem))
}

export const convertProductReview = (
  data: Record<string, any>
): ProductReview => {
  return {
    rating: data.CustomerRating,
    content: data.Content,
    date: new Date(data.CreationDate),
    firstName: data.FirstName,
  }
}

// value possible be 'nieuw2022september'
export const getNewField = (fieldVal: string) => {
  try {
    if (!fieldVal) return
    const value = fieldVal.trim().toLowerCase()

    const year = value.match(/\d{4}/g)?.[0]
    if (!year) return

    const monthPattern = new RegExp(
      String.raw`(${monthsNL.join('|')})(\b|\B)`,
      'g'
    )
    const month = value.match(monthPattern)?.[0]
    const quarter = value.match(/(q1|q2|q3|q4)(\b|\B)/)?.[0]
    if (!month && !quarter) return

    const monthOfEN = month && monthsEN[monthsNL.indexOf(month)]
    const quarterVal = quarter && Quarter[quarter]

    const startDate = new Date(`${quarter ? quarterVal : monthOfEN},1 ${year}`)

    const endDate = new Date(
      new Date(startDate)?.setMonth(startDate?.getMonth() + (quarter ? 3 : 1))
    )

    const labelPattern = new RegExp(String.raw`(${month}|${year})(\b|\B)`, 'g')
    const label = value.split(labelPattern)?.[0]

    return {
      inRange: inRange(startDate?.toISOString(), endDate?.toISOString()),
      content: label ?? '',
    }
  } catch {
    return {}
  }
}

export const convertWebshopBanner = (webshopBanner: string) => {
  let result: WebshopBanner | null = null

  if (webshopBanner) {
    const tagItem = getNewField(webshopBanner)
    if (!tagItem) {
      result = {
        name: webshopBanner,
        type: WebshopBannerType.Label,
      }
    } else if (tagItem.inRange) {
      result = {
        name: tagItem.content,
        type: WebshopBannerType.Tag,
      }
    }
  }

  return result
}

export const convertProductTags = (
  tagFields: { key: string; value: string }[]
): ProductLabel[] => {
  // related issue: https://dev.azure.com/firstfocusim/www.beauty-x.nl/_workitems/edit/5409
  const result: ProductLabel[] = []

  tagFields.forEach((item) => {
    if (!item.value) return
    result.push({
      name: item.value,
      key: item.key,
    })
  })

  return result
}

export const getFieldValue = function <T>(
  fields: Record<string, string>,
  option: GetValueOption<T>
): T {
  const { name, convertValue, defaultValue } = option
  const value = fields[name.toLowerCase()]
  if (!value) return defaultValue

  return convertValue(value)
}

export const getFieldLowerValue = function <T>(
  fields: Record<string, string>,
  option: GetValueOption<T>
): T {
  const { name, convertValue, defaultValue } = option
  return getFieldValue(fields, {
    name,
    convertValue: (v) => convertValue(v.toLowerCase()),
    defaultValue,
  })
}

export const convertProduct = (
  data: any,
  extraFields?: Record<string, any>
): Product => {
  // Prepare fields convert
  const fields: Dictionary<string> = {}
  if (data.Fields) {
    data.Fields.forEach((field: any) => {
      fields[field.Code.toLowerCase()] = field.Value
    })
  }

  const getValue = <T>(
    name: string,
    convertValue: (value: string) => T,
    defaultValue: T
  ) => {
    return getFieldValue(fields, {
      name,
      convertValue,
      defaultValue,
    })
  }

  const getLowerValue = <T>(
    name: string,
    convertValue: (value: string) => T,
    defaultValue: T
  ) => {
    return getFieldLowerValue(fields, {
      name,
      convertValue,
      defaultValue,
    })
  }

  const getMemoByType = (type: string) => {
    return data.Memos.find(
      (memo: any) =>
        memo.MemoType && memo.MemoType.toLowerCase() === type.toLowerCase()
    )
  }

  // review
  const reviewStats = extraFields?.ReviewStats

  const type = data.Type
  const activeWebNodes = data.ActiveWebNodes?.map((item: any) =>
    convertWebNode(item)
  )

  // Build product
  const product: Product = {
    id: data.Id,
    type,
    name: data.OnlineDescription || data.Description,
    url: extraFields?.url,
    number: data.Number,
    numberTrimmed: trimString(data.Number, 6),
    scanCode: data.DefaultScanCode?.Code,

    img: data.Images?.length
      ? (data.Images.find((img: any) => img.Type === 'Front') ?? data.Images[0])
          ?.Url
      : undefined,
    gallery: data.Images?.map((img: any, index: number) => ({
      type: 'image',
      order: img.Type === 'Front' ? 0 : index + 1,
      src: img.Url,
    })),

    isActive: data.State === 'Active',
    isVirtual: data.Type === 'Variant', // getLowerValue('HOOFDPRODUCT', (v) => v, null) === 'ja',
    isViewable: true,
    isValidInCart: true,

    ...convertProductAvailibility(extraFields?.Availability),

    priceInclTax: parseFloat((data.PriceInclTax || 0).toFixed(2)),
    priceExclTax: parseFloat((data.PriceExclTax || 0).toFixed(2)),
    taxRate: data.TaxRate / 100,

    brand: data.Brand && convertBrand(data.Brand),

    unitPerProduct: getValue(
      'QtyPerBaseCompUnitCode',
      (v) => (v ? parseFloat(v) : 0),
      0
    ),

    // reveiws
    rating: reviewStats?.Rating ?? 0,
    reviewCount: reviewStats?.Count ?? 0,
    ratings: convertRatings(reviewStats?.Ratings ?? []),
  }

  // webNode
  if (activeWebNodes?.length) {
    product.activeWebNodes = activeWebNodes
    product.webNode = activeWebNodes[0]
  }

  // convert to client-side isOrderable
  product.isViewable =
    (activeWebNodes ? activeWebNodes.length > 0 : true) &&
    !VIEWABLE_EXCLUDE_TYPES.has(product.type as string) &&
    (extraFields?.inMainTree ?? true)

  product.isValidInCart = checkValidInCart(product)
  product.isOrderable = checkOrderable(product)

  // maxInCart
  const maxInCart = getValue(
    'MaxInCart',
    (v) => {
      const num = Number(v)
      return isNaN(num) ? null : num
    },
    null
  )

  if (maxInCart !== null) {
    product.maxInCart = maxInCart
  }

  // Gallery
  const videoUrl = getValue('VIDEOURL', (v) => v, null)
  if (videoUrl) {
    product.gallery ??= []
    product.gallery.push({
      type: videoUrl.includes('youtube') ? 'youtube' : 'video',
      order: 999999,
      src: videoUrl,
    })
  }

  // Unit and price
  const unitPriceExclTax = getValue('CompPrice', (v) => parseFloat(v), 0)
  const unit = getValue('BaseCompUnitCode', (v) => v, null)
  if (unitPriceExclTax && unit) {
    product.unit = unit
    product.unitPriceExclTax = unitPriceExclTax
    product.unitPriceInclTax = unitPriceExclTax * (1 + (product.taxRate ?? 0))
  }

  // size
  const size = getValue('Inhoud', (v) => v, null)
  if (size) {
    product.size = size
  }

  // color hex
  const colorHex = getValue('Kleurcode', (v) => v, null)
  if (colorHex) {
    product.colorHex = getValidColorHex(colorHex)
  }

  // color hex
  const colorName = getValue('Kleur', (v) => v, null)
  if (colorName) {
    product.colorName = colorName
  }

  // Discounts
  if (data.Discounts?.length) {
    product.discounts = convertDiscounts(data.Discounts)
  }

  // Specification preparation
  if (data.Fields) {
    product.fields = data.Fields.map((data: any) => ({
      code: data.Code,
      label: data.Description,
      value: data.Value,
    }))
  }

  // Pricing
  if (data.Discount) {
    product.discount = {
      id: data.Discount.DiscountId,
      name: data.Discount.Description,
    }
    product.oldPriceInclTax = product.priceInclTax
    product.oldPriceExclTax = product.priceExclTax
    product.priceInclTax = data.Discount.PriceInclTax
    product.priceExclTax = data.Discount.PriceExclTax
  }

  // webshopbanner
  const webshopBanner = convertWebshopBanner(
    getLowerValue('webshopbanner', (v) => v, '')
  )
  if (webshopBanner) {
    product.webshopBanner = webshopBanner
    // Label
    if (webshopBanner.type === WebshopBannerType.Label) {
      product.label = {
        key: webshopBanner.name,
      }
    }
  }

  // tags
  product.tags = convertProductTags(
    TAG_FIELD_KEYS.map((key) => ({
      key,
      value: getValue(key, (v) => v, ''),
    }))
  )

  // Big contents
  if (data.Memos) {
    const internetMemo = getMemoByType('InternetMemo')
    const compositionMeno = getMemoByType('Composition')
    const usageMeno = getMemoByType('Usage')

    product.description = internetMemo?.Text
      ? decodeHtml(internetMemo.Text)
      : ''
    product.ingredients = compositionMeno?.Text
      ? decodeHtml(compositionMeno.Text)
      : ''
    product.usageDescription = usageMeno?.Text ? decodeHtml(usageMeno.Text) : ''
  }

  // Stock
  if (data.StockInfo) {
    product.stock = data.StockInfo.map((stock: any) => convertStock(stock))
  }

  // Brand
  if (
    data.Brand &&
    extraFields &&
    extraFields.BrandMemos &&
    extraFields.BrandMemos.length
  ) {
    product.brand = convertBrand(data.Brand, extraFields)
  }

  // Attached files
  if (extraFields && extraFields.Memos && extraFields.Memos.length) {
    product.files = extraFields.Memos.filter(
      (memo: any) => memo && memo.FileName && memo.Id
    ).map((memo: any) => {
      return {
        fileName: memo.FileName,
        memoId: memo.Id,
      }
    })
  }
  if (extraFields && extraFields.MoreLessLevels) {
    convertMoreLess(product, extraFields.MoreLessLevels)
  }

  if (product.isVirtual && extraFields?.VariantProductsJson) {
    product.variants = convertVariantProductsJson(
      extraFields.VariantProductsJson
    )
  }

  return product

  // function convertBouwmaatProduct() {
  //   const safetyLogos = getValue('GEVAARLOGO', (v) => v, null)
  //   product.safetyLogos = safetyLogos ? safetyLogos.split('|') : []

  //   product.deliveryType = getLowerValue(
  //     'DeliveryType',
  //     (v) => v as ProductDeliveryType,
  //     null
  //   )

  //   product.deliverySize = getLowerValue(
  //     'DeliveryCostCat',
  //     (v) =>
  //       v === 'grootgoed'
  //         ? ProductDeliverySize.Large
  //         : ProductDeliverySize.Small,
  //     ProductDeliverySize.Small
  //   )
  // }
}

export const convertProducts = (
  products: Array<any>,
  productUrls: Dictionary<string>,
  extraFields?: Dictionary<any>
): Product[] => {
  return products.map((product) =>
    convertProduct(
      product,
      Object.assign(
        {
          url: productUrls[product.Id],
        },
        extraFields?.[product.Id]
      )
    )
  )
}

export const convertProductSelection = (
  data: any,
  extraFields?: Record<string, any>
): ProductSelection => {
  return {
    id: data.Id,
    productId: data.ProductId,
    product: convertProduct(data.Product, extraFields),
  }
}

export const convertProductSelections = (
  data: any,
  urls: any,
  extraFields?: any
) => {
  return (
    data?.map((item: any) =>
      convertProductSelection(item, {
        url: urls?.[item.ProductId],
        ...(extraFields?.[item.ProductId] ?? {}),
      })
    ) ?? []
  )
}

export const convertProductStock = (data: any): ProductStock[] => {
  return data.map((store: any) => ({
    storeId: store.StoreId,
    quantity: store.AvailableQuantity > 0 ? store.AvailableQuantity : 0,
    purchaseQuantity:
      store.PurchaseOrderQuantity > 0 ? store.PurchaseOrderQuantity : 0,
  }))
}

export const convertProductAvailibility = (
  data: any
): ProductAvailabilityInServer => {
  const stockQuantity = data?.StockQuantity ?? 0

  return {
    isOrderable: data?.IsOrderable ?? false,
    inStock: data?.HasStock ?? false,
    stockQuantity: stockQuantity > 0 ? stockQuantity : 0,
    maxStockEnabled: data?.MaxStockEnabled ?? false,
    isDeliverable: data?.IsDeliverable ?? false,
    isPickupable: data?.IsPickupable ?? false,
  }
}
