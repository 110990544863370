var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"inline-flex align-middle select-none",class:[_vm.disabled ? 'cursor-not-allowed opacity-75' : 'cursor-pointer']},[_c('span',{staticClass:"flex-shrink-0 inline-flex items-center justify-center w-15px h-15px border mt-[3px]",class:[
      _vm.isChecked ? 'bg-white' : 'bg-black bg-opacity-10',
      {
        'border-pfm-grey-400 ': _vm.status === 'default',
        'border-pfm-error': _vm.status === 'error',
        hidden: _vm.isHiddenCheckbox,
        'rounded-full': _vm.isRadioTheme,
      } ]},[_c('input',_vm._b({staticClass:"sr-only",attrs:{"type":"checkbox","disabled":_vm.disabled},domProps:{"value":_vm.value,"checked":_vm.isChecked},on:{"change":function($event){return _vm.onChange($event.target.checked)}}},'input',_vm.$attrs,false)),_vm._v(" "),(_vm.isChecked)?_c('span',{staticClass:"block w-9px h-9px bg-pfm-primary",class:{
        'rounded-full': _vm.isRadioTheme,
      }}):_vm._e()]),_vm._v(" "),(_vm.slots.default || _vm.label)?_c('span',{staticClass:"inline-block text-pfm-primary leading-tight",class:{ 'px-2': !_vm.isHiddenCheckbox }},[_vm._t("default",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }