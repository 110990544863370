var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseOverlay',{staticClass:"min-h-[240px]",attrs:{"show":_vm.brandLoading}},[_c('div',{staticClass:"flex"},[(_vm.specialBrandColumns && _vm.specialBrandColumns.length)?_c('div',{staticClass:"w-1/3 flex-shrink-0"},[_c('ul',{staticClass:"mb-1 grid grid-cols-2 gap-x-4"},_vm._l((_vm.specialBrandColumns),function(item,index){return _c('li',{key:index},[_c('h4',{staticClass:"font-bold flex items-center border-b border-black mb-1",attrs:{"data-testid":"brand-sub-item-title"}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n              "+_vm._s(item.name)+"\n            ")])]),_vm._v(" "),(item.brands && item.brands.length)?_c('ul',_vm._l((item.brands.slice(0, 7)),function(subItem){return _c('li',{key:("sub-" + (subItem.id))},[_c('NuxtLink',{staticClass:"font-light hover:text-pfm-secondary",attrs:{"to":_vm.localePath(
                    subItem.url
                      ? {
                          name: 'brand-lister-all',
                          params: { pathMatch: subItem.url },
                        }
                      : {
                          name: 'index',
                        }
                  ),"data-testid":"brand-sub-item"}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n                  "+_vm._s(subItem.title)+"\n                ")])])],1)}),0):_vm._e()])}),0)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"w-2/3 pl-6 ml-auto"},[(_vm.brandAlphabetList && _vm.brandAlphabetList.length)?_c('ul',{staticClass:"flex items-center border-b border-black justify-between mb-1"},_vm._l((_vm.brandAlphabetList),function(item,index){return _c('li',{key:("alphabet-key-" + index),staticClass:"px-1 first:pl-0 last:pr-0",attrs:{"data-target-key":item}},[_c('button',{staticClass:"hover:text-pfm-secondary font-bold",class:{
              'opacity-50 pointer-events-none': !item.active,
            },attrs:{"type":"button","disable":item.active,"data-testid":"brand-alphabet"},on:{"click":function($event){return _vm.onAnchorClick(item.label)}}},[_vm._v("\n            "+_vm._s(item.label)+"\n          ")])])}),0):_vm._e(),_vm._v(" "),_c('ClientOnly',[(_vm.convertBrands && _vm.convertBrands.length)?_c('div',{staticClass:"brand-alphabet-swiper relative"},[_c('Swiper',{ref:"swiperEl",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.convertBrands),function(group,groupIndex){return _c('SwiperSlide',{key:("group-" + groupIndex),attrs:{"data-slide-index":groupIndex}},[_c('ul',_vm._l((group),function(item,ItemIndex){return _c('li',{key:("item-" + ItemIndex),staticClass:"pr-3",attrs:{"data-alphabet-key":item.key ? item.key : undefined}},[(!item.key)?_c('NuxtLink',{staticClass:"block truncate hover:text-pfm-secondary",attrs:{"to":_vm.localePath(
                        item.url
                          ? {
                              name: 'brand-lister-all',
                              params: { pathMatch: item.url },
                            }
                          : {
                              name: 'index',
                            }
                      ),"title":item.key ? undefined : item.name,"data-testid":"brand-sub-item"}},[_vm._v("\n                    "+_vm._s(item.name)+"\n                  ")]):_c('p',{staticClass:"font-bold"},[_vm._v(_vm._s(item.name))])],1)}),0)])}),_vm._v(" "),_vm._l((3),function(n){return _c('SwiperSlide',{key:("placeholder-" + n)},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(n))])])})],2),_vm._v(" "),_c('div',{staticClass:"swiper-btn-prev cursor-pointer absolute top-1/2 right-full z-20 mr-2 -translate-y-1/2",on:{"click":function($event){return _vm.onNavigate(_vm.NavigationType.Prev)}}},[_c('BaseIcon',{staticClass:"w-6 h-6",attrs:{"name":"caret-left"}})],1),_vm._v(" "),_c('div',{staticClass:"swiper-btn-next cursor-pointer absolute top-1/2 right-0 -mr-1 z-20 -translate-y-1/2",on:{"click":function($event){return _vm.onNavigate(_vm.NavigationType.Next)}}},[_c('BaseIcon',{staticClass:"w-6 h-6",attrs:{"name":"caret-right"}})],1)],1):_vm._e()])],1)]),_vm._v(" "),(_vm.brandsSlider && _vm.brandsSlider.length)?_c('ul',{staticClass:"flex flex-wrap items-center pt-5 space-x-2"},_vm._l((_vm.brandsSlider),function(item){return _c('li',{key:item.id,staticClass:"mb-1"},[_c('NuxtLink',{staticClass:"flex items-center h-6",attrs:{"to":_vm.localePath(
            item.url
              ? {
                  name: 'brand-lister-all',
                  params: { pathMatch: item.url },
                }
              : {
                  name: 'index',
                }
          )}},[(item.image && item.image.src)?_c('img',{staticClass:"object-contain max-h-full",attrs:{"src":((item.image.src) + "?w=60"),"alt":item.title,"height":"auto","width":"auto"}}):_c('span',[_vm._v(_vm._s(item.title))])])],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }