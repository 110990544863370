import { Voucher, Coupon } from '~/types/promotion'

export const convertVoucher = (data: any): Voucher => {
  const result: Voucher = {
    id: data.Id,
  }

  if (data.Type) {
    result.type = {
      id: data.Type.Id,
      name: data.Type.Description,
    }
  }

  return result
}

export const convertCoupon = (data: any): Coupon => {
  return {
    id: data.Id,
    name: data.Description,
  }
}
