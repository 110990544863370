import { AxiosInstance, AxiosResponse } from 'axios'
import { convertCustomerAddresses } from '../../deserializers/customer'
import { convertAddress } from '../../deserializers/geo'
import { CustomerAddress } from '~/types/customer'

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/customer/addresses'

  return {
    async getAddresses(type: string | null = null): Promise<CustomerAddress[]> {
      const response: AxiosResponse = await instance.get(`/${base}`, {
        params: {
          addressType: type,
        },
      })
      if (!response.data.Success) return []
      return convertCustomerAddresses(response.data.Addresses)
    },

    async addAddress(address: CustomerAddress): Promise<number | null> {
      const response: AxiosResponse = await instance.post(`/${base}`, {
        ...address,
        addressType: address.type,
      })
      if (!response.data.Success) return null
      return response.data.AddressId
    },

    async updateAddress(address: CustomerAddress): Promise<boolean> {
      const { data } = await instance.put(`/${base}/${address.id}`, address)
      return !!data?.Success
    },

    async removeAddress(addressId: number): Promise<boolean> {
      const { data } = await instance.delete(`/${base}/${addressId}`)
      return !!data?.Success
    },

    async findAddress(postalCode: string, houseNumber: string): Promise<any> {
      const response: AxiosResponse = await instance.post(`/api/aspos/locations/findaddress`, {
        postalCode: postalCode.replace(" ", ""),
        houseNumber
      })
      if (!response.data.Success) {
        return console.error(response.data?.Message) // eslint-disable-line no-console
      }
      return convertAddress(response.data?.Data)
    },
  }
}
