import {
  useContext,
  ssrRef,
  readonly,
  inject,
  InjectionKey,
  provide,
  Ref,
  DeepReadonly
} from '@nuxtjs/composition-api'
import { useLoading } from './ui/useLoading'
import { GlobalConfig } from '~/types/common'

type WebNodeResult = {
  config: DeepReadonly<Ref<GlobalConfig | null>>
  loading: Ref<boolean>
  ensureConfig: () => Promise<void>
}

export const configKey: InjectionKey<WebNodeResult> =
  Symbol('Provider:GlobalConfig')

export const provideConfig = () => {
  const { app } = useContext()
  const config = ssrRef<GlobalConfig | null>(null)
  const loading = useLoading()

  const ensureConfig = async () => {
    if (config.value) return

    await loading.scope(async () => {
      if (config.value) return

      config.value = await app.$api.config.getConfig()
    })
  }

  provide(configKey, {
    loading: loading.value,
    config: readonly(config),
    ensureConfig,
  })
}

export const useConfig = () => {
  const result = inject(configKey)

  if (result == null) {
    throw new Error('global config provider not set')
  }

  return result
}

