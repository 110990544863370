var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('div',{staticClass:"mx-auto max-w-pfm-wrapper border-b border-pfm-grey-400 relative flex items-center"},[_c('ul',{staticClass:"text-pfm-primary -mx-2.5 flex items-center xl:-mx-4"},[_c('li',[_c('AppHeaderNavPopover',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('NuxtLink',{staticClass:"relative uppercase tracking-1 font-bold flex items-center h-12.5 px-2.5 xl:px-4",attrs:{"to":_vm.localePath({
                  name: 'brand',
                }),"data-testid":"menu-item"}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n                "+_vm._s(_vm.$t('menu.brand'))+"\n              ")]),_vm._v(" "),(active)?_c('span',{staticClass:"block absolute inset-x-2.5 xl:inset-x-4 z-10 bottom-2.5 h-0.5 bg-pfm-secondary"}):_vm._e()])]}},{key:"default",fn:function(){return [_c('div',{staticClass:"bg-white text-black mt-px border-b border-pfm-grey-600 p-5 -mx-5 min-h-[240px]"},[_c('BrandAlphabetMegaMenu')],1)]},proxy:true}])})],1),_vm._v(" "),(_vm.categories && _vm.categories.length)?_vm._l((_vm.categories),function(item,index){return _c('li',{key:("categories-" + index)},[_c('AppHeaderNavPopover',{scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
                var active = ref.active;
return [_c('NuxtLink',{staticClass:"relative uppercase tracking-1 font-bold flex items-center h-12.5 px-2.5 xl:px-4",attrs:{"to":item.code === 'ACTIES'
                    ? _vm.localePath({
                        name: 'all',
                        params: { pathMatch: 'actie' },
                      })
                    : _vm.localePath(
                        item.url
                          ? {
                              name: 'product-lister-all',
                              params: { pathMatch: item.url },
                            }
                          : {
                              name: 'index',
                            }
                      ),"data-testid":"menu-item"}},[_c('span',{staticClass:"block truncate"},[_vm._v(_vm._s(item.name))]),_vm._v(" "),(active)?_c('span',{staticClass:"block absolute inset-x-2.5 xl:inset-x-4 z-10 bottom-2.5 h-0.5 bg-pfm-secondary"}):_vm._e()])]}},{key:"default",fn:function(){return [(item.children && item.children.length)?_c('ul',{staticClass:"bg-white text-black capitalize mt-px border-b border-pfm-grey-600 p-5 -mx-5 min-h-[240px] first-line:first-letter:capitalize columns-4 gap-4 lg:columns-6 lg:gap-8"},_vm._l((item.children),function(subItem,subIndex){return _c('li',{key:("sub-" + subIndex),staticClass:"break-inside-avoid-column mb-7"},[_c('NuxtLink',{staticClass:"font-bold flex items-center border-b border-black hover:text-pfm-secondary mb-1",attrs:{"to":_vm.localePath(
                        subItem.url
                          ? {
                              name: 'product-lister-all',
                              params: { pathMatch: subItem.url },
                            }
                          : {
                              name: 'index',
                            }
                      ),"data-testid":"menu-sub-item-title"}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n                      "+_vm._s(subItem.name)+"\n                    ")])]),_vm._v(" "),(subItem.children && subItem.children.length)?_c('ul',[_vm._l((subItem.children.slice(0, 7)),function(subSubItem,subSubIndex){return _c('li',{key:("sub-sub-" + subSubIndex)},[_c('NuxtLink',{staticClass:"font-light hover:text-pfm-secondary",attrs:{"to":_vm.localePath(
                            subSubItem.url
                              ? {
                                  name: 'product-lister-all',
                                  params: { pathMatch: subSubItem.url },
                                }
                              : {
                                  name: 'index',
                                }
                          ),"data-testid":"menu-sub-item"}},[_c('span',{staticClass:"block truncate"},[_vm._v("\n                          "+_vm._s(subSubItem.name)+"\n                        ")])])],1)}),_vm._v(" "),(subItem.children.length > 7)?_c('li',[_c('NuxtLink',{staticClass:"font-light flex items-center hover:text-pfm-secondary",attrs:{"to":_vm.localePath(
                            subItem.url
                              ? {
                                  name: 'product-lister-all',
                                  params: { pathMatch: subItem.url },
                                }
                              : {
                                  name: 'index',
                                }
                          ),"data-testid":"menu-sub-item-more"}},[_c('BaseIcon',{staticClass:"w-2.5 h-2.5 mr-2",attrs:{"name":"pfm-arrow-forward"}}),_vm._v("\n                        "+_vm._s(_vm.$t('text.allCategories'))+"\n                      ")],1)],1):_vm._e()],2):_vm._e()],1)}),0):_vm._e()]},proxy:true}],null,true)})],1)}):_vm._e()],2),_vm._v(" "),(_vm.mainMenu && _vm.mainMenu.length)?_c('ul',{staticClass:"ml-auto text-pfm-primary flex items-center"},_vm._l((_vm.mainMenu),function(menu){return _c('li',{key:("main-" + (menu.key))},[_c(menu.alternateUrl ? 'a' : 'NuxtLink',_vm._b({tag:"component",staticClass:"relative group uppercase tracking-1 font-bold flex items-center h-12.5 px-2.5 xl:px-4",attrs:{"data-testid":"menu-item"}},'component',
            menu.alternateUrl
              ? {
                  href: menu.alternateUrl,
                }
              : {
                  to: _vm.localePath(
                    menu.url
                      ? {
                          name: 'all',
                          params: { pathMatch: menu.url },
                        }
                      : {
                          name: 'index',
                        }
                  ),
                }
          ,false),[_vm._v("\n          "+_vm._s(menu.name)+"\n          "),_c('span',{staticClass:"hidden absolute inset-x-2.5 xl:inset-x-4 z-10 bottom-2.5 h-0.5 bg-pfm-secondary group-hover:block"})])],1)}),0):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }