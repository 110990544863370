import { Dictionary, uniqBy } from 'ramda'
import { AxiosInstance, AxiosResponse } from 'axios'
import {
  Product,
  ProductConnectionType,
  ProductListOptions,
  ProductReview,
  ProductSelection,
  ProductStock,
  ProductPageRequest,
  GetProductParams,
  ProductStockRefresh,
} from '@/types/product'
import {
  convertProduct,
  convertProducts,
  convertProductSelection,
  convertProductSelections,
  convertProductReview,
  convertStock,
  convertProductAvailibility,
  convertProductStock,
} from '~/lib/api/deserializers/product'
import { convertWebNode } from '~/lib/api/deserializers/webNode'
import { ReviewSorting } from '~/composables/product/useProductReview'

enum ReviewSortField {
  CustomerRating = 'CustomerRating',
  CreationDate = 'CreationDate',
}

const SORT_BY_PARAMS: Readonly<
  Record<
    ReviewSorting,
    {
      field: ReviewSortField
      ascending: boolean
    }
  >
> = {
  [ReviewSorting.NewestFirst]: {
    field: ReviewSortField.CreationDate,
    ascending: false,
  },
  [ReviewSorting.OldestFirst]: {
    field: ReviewSortField.CreationDate,
    ascending: true,
  },
  [ReviewSorting.MostStars]: {
    field: ReviewSortField.CustomerRating,
    ascending: false,
  },
  [ReviewSorting.FewestStars]: {
    field: ReviewSortField.CustomerRating,
    ascending: true,
  },
}

export default function (instance: AxiosInstance) {
  const base = 'api/aspos/products'

  return {
    async getListOptions(): Promise<ProductListOptions | null> {
      const response: AxiosResponse = await instance.get(
        `/${base}/elastic-search/config`
      )
      if (!response.data.Success) return null

      const data = response.data.Data

      const facetLabels = data.FacetTitleOverride
      const sortOptions = data.Sorting.map((item: any) => ({
        label: item.Title,
        field: item.FieldName,
      }))

      return {
        limit: data.MaximumProdCountToShowDedicatedCategoryPage || 12,
        facetLabels,
        sortOptions,
      }
    },

    async getByUrl(
      url: string,
      payload?: GetProductParams
    ): Promise<Product | null> {
      const defaultParams = {
        includingWebNode: true,
        realtimeStock: true,
      }
      const params = Object.assign(defaultParams, payload)
      const response: AxiosResponse = await instance.get(
        `/${base}/url/${url}`,
        {
          params,
        }
      )

      if (!response.data.Success) return null
      // mock ReviewStats data
      // response.data.ExtraFields.ReviewStats = {
      //   "Count": 15,
      //   "Rating": 4,
      //   "Ratings": [
      //       {
      //           "Rating": 5,
      //           "Count": 8
      //       },
      //       {
      //           "Rating": 1,
      //           "Count": 1
      //       },
      //       {
      //           "Rating": 2,
      //           "Count": 1
      //       },
      //       {
      //           "Rating": 3,
      //           "Count": 3
      //       },
      //       {
      //           "Rating": 4,
      //           "Count": 2
      //       }
      //   ]
      // }

      const product = convertProduct(response.data.Product, {
        url: response.data.ProductUrl,
        ...(response.data.ExtraFields ?? {}),
      })
      if (response.data.WebNode) {
        product.webNode = convertWebNode(response.data.WebNode)
      }

      return product
    },

    async getByIds(productIds: string): Promise<Product[] | []> {
      const response: AxiosResponse = await instance.get(
        `/${base}/ids?productIds=${productIds}`,
        {
          params: {
            realtimeStock: true,
            fetchExtraFields: true,
          },
        }
      )

      if (!response.data.Success) return []

      const products = convertProducts(
        response.data.Products,
        response.data.ProductUrls,
        response.data.ExtraFields
      )

      return products
    },

    /*
     * Product connections
     */
    async getConnections(
      productId: number,
      connectionTypes: ProductConnectionType[],
      limit = 50
    ): Promise<Dictionary<Product[]>> {
      const { data } = await instance.get(`/${base}/${productId}/connections`, {
        params: {
          fetchExtraFields: true,
          realtimeStock: true,
          productId,
          connectionTypes,
          limit,
        },
      })

      if (!data.Success) return {}

      const groups: Dictionary<Product[]> =
        data.Connections?.reduce(
          (prev: Dictionary<Product[]>, current: Dictionary<any>) => {
            let group = prev[current.Type]
            if (!group) {
              prev[current.Type] = group = []
            }

            let tempProduct = current.Product
            if (current.ProductId === productId && current.ParentProduct) {
              tempProduct = current.ParentProduct
            }

            if (
              tempProduct.Id !== productId &&
              tempProduct.ActiveWebNodes?.length
            ) {
              const product = convertProduct(tempProduct, {
                url: data.ProductUrls?.[tempProduct.Id],
                ...(data.ExtraFields?.[tempProduct.Id] ?? {}),
              })
              group.push(product)
            }

            return prev
          },
          {}
        ) ?? {}

      // unify each group by productId
      for (const key in groups) {
        groups[key] = uniqBy((product) => product.id, groups[key])
      }

      return groups
    },

    /*
     * Product reviews
     */

    async getReviews(payload: {
      productId: number
      sorting?: ReviewSorting
    }): Promise<ProductReview[]> {
      const { productId, sorting } = payload
      const sortBy = sorting && SORT_BY_PARAMS[sorting]
      const response: AxiosResponse = await instance.get(
        `/${base}/${productId}/reviews`,
        {
          params: {
            sortField: sortBy?.field,
            sortAscending: sortBy?.ascending,
          },
        }
      )

      if (!response.data.Success) return []

      return (
        response.data.Reviews?.map((review: any) =>
          convertProductReview(review)
        ) ?? []
      )
    },

    async addReview(
      productId: number,
      review: ProductReview,
      isVisible = false
    ): Promise<boolean> {
      let success = false
      try {
        const response: AxiosResponse = await instance.post(
          `/${base}/${productId}/reviews`,
          {
            customerRating: review.rating,
            content: review.content,
            isVisible,
          }
        )
        success = response.data.Success
      } catch {
        success = false
      }

      return success
    },

    /*
     * Popular products
     */

    async getByWebnodeId(
      webNodeId: number | string,
      payload?: {
        offset?: number
        limit?: number
      }
    ): Promise<Product[] | []> {
      const response: AxiosResponse = await instance.get(
        `/api/aspos/webnodes/${webNodeId}/products`,
        {
          params: {
            fetchExtraFields: true,
            realtimeStock: true,
            ...payload,
          },
        }
      )

      if (!response.data.Products?.length) return []

      // mock sample product
      //   response.data.ProductUrls[13957] = "rinse-off-eye-make-up-solvent-0001013957"
      //   response.data.Products.push({
      //     "Id": 13957,
      //     "Number": "0001013957",
      //     "Description": "CLINIQUE RINSE OFF EYE MAKE-UP SOLVENT 125ML",
      //     "SecondDescription": "",
      //     "OnlineDescription": "Rinse Off Eye Make-Up Solvent",
      //     "PriceInclTax": 26.25,
      //     "PriceExclTax": 21.6942,
      //     "ListPrice": 26.25,
      //     "TaxRate": 21,
      //     "PurchasePrice": 12.81,
      //     "DiscountPrice": 0,
      //     "DefaultScanCode": {
      //         "Id": 327523,
      //         "Code": "0020714000318",
      //         "Quantity": 1
      //     },
      //     "Discount": {
      //         "DiscountId": 2778,
      //         "Description": "Interfiliaal prijslijst gem",
      //         "AmountExclTax": 10.55,
      //         "AmountInclTax": 12.77,
      //         "PriceExclTax": 11.1442,
      //         "PriceInclTax": 13.48,
      //         "Percentage": 48
      //     },
      //     "Discounts": [],
      //     "Fields": [
      //         {
      //             "Code": "Lijn",
      //             "Description": "Lijn",
      //             "Value": "SKINCARE"
      //         },
      //         {
      //             "Code": "Subgroep",
      //             "Description": "Subgroep",
      //             "Value": "eye make-up remover"
      //         },
      //         {
      //             "Code": "Geschikt_voor",
      //             "Description": "Geschikt voor",
      //             "Value": "Ogen"
      //         },
      //         {
      //             "Code": "Textuur",
      //             "Description": "Textuur",
      //             "Value": "Lotion"
      //         },
      //         {
      //             "Code": "Huidtype",
      //             "Description": "Huidtype",
      //             "Value": "Alle huidtypes"
      //         },
      //         {
      //             "Code": "Brievenbuspakje",
      //             "Description": "Brievenbuspakje",
      //             "Value": "no"
      //         },
      //         {
      //             "Code": "Inhoud",
      //             "Description": "Inhoud",
      //             "Value": "125 ml"
      //         },
      //         {
      //             "Code": "Bulletpoint 1",
      //             "Description": "Bulletpoint 1",
      //             "Value": "Oogmake-up remover 125 ml"
      //         },
      //         {
      //             "Code": "Bulletpoint 2",
      //             "Description": "Bulletpoint 2",
      //             "Value": "REINIGEND: Reinigt de ogen op een heel zachte manier"
      //         },
      //         {
      //             "Code": "Bulletpoint 3",
      //             "Description": "Bulletpoint 3",
      //             "Value": "Huidtype: Alle huidtypen"
      //         },
      //         {
      //             "Code": "Bulletpoint 4",
      //             "Description": "Bulletpoint 4",
      //             "Value": "Gebruik: Goed schudden voor gebruik, doordrenk een watje met remover en verwijder voorzichtig de oog- en lipmake-up"
      //         },
      //         {
      //             "Code": "Bulletpoint 5",
      //             "Description": "Bulletpoint 5",
      //             "Value": "100% parfumvrij"
      //         }
      //     ],
      //     "Images": [
      //         {
      //             "Id": 2557,
      //             "RelatedId": 13957,
      //             "Type": "Front",
      //             "Url": "https://testimageretrieval.aspos.nl/Product/Front/181217/b60cb065-3781-4c06-b183-cb4f63a62ad6.jpg"
      //         }
      //     ],
      //     "Memos": [
      //         {
      //             "MemoType": "InternetMemo",
      //             "Text": "Unieke formule verwijdert snel alle oogmake-up, van wimpers tot wenkbrauwen. Perfect voor snelle make-up wisselingen, touch-ups of correcties. Reinigt oogomtrek makkelijk, zonder de andere make-up te hinderen. Wrijven is niet noodzakelijk. Vetvrij, waas-vrij, prik-vrij. Door oogartsen getest."
      //         }
      //     ],
      //     "StockInfo": [
      //         {
      //             "AvailableQuantity": 7,
      //             "PhysicalStockQuantity": 7,
      //             "MinimumStockQuantity": 5,
      //             "MaximumStockQuantity": 5,
      //             "StoreId": 10,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 2,
      //             "PhysicalStockQuantity": 2,
      //             "MinimumStockQuantity": 2,
      //             "MaximumStockQuantity": 2,
      //             "StoreId": 11,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 1,
      //             "PhysicalStockQuantity": 1,
      //             "MinimumStockQuantity": 1,
      //             "MaximumStockQuantity": 1,
      //             "StoreId": 12,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 1,
      //             "PhysicalStockQuantity": 1,
      //             "MinimumStockQuantity": 1,
      //             "MaximumStockQuantity": 1,
      //             "StoreId": 13,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 2,
      //             "PhysicalStockQuantity": 2,
      //             "MinimumStockQuantity": 2,
      //             "MaximumStockQuantity": 2,
      //             "StoreId": 15,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 1,
      //             "PhysicalStockQuantity": 1,
      //             "MinimumStockQuantity": 2,
      //             "MaximumStockQuantity": 2,
      //             "StoreId": 16,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 3,
      //             "PhysicalStockQuantity": 3,
      //             "MinimumStockQuantity": 4,
      //             "MaximumStockQuantity": 4,
      //             "StoreId": 17,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 1,
      //             "PhysicalStockQuantity": 1,
      //             "MinimumStockQuantity": 1,
      //             "MaximumStockQuantity": 1,
      //             "StoreId": 6,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 4,
      //             "PhysicalStockQuantity": 4,
      //             "MinimumStockQuantity": 2,
      //             "MaximumStockQuantity": 2,
      //             "StoreId": 7,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 1,
      //             "PhysicalStockQuantity": 1,
      //             "MinimumStockQuantity": 1,
      //             "MaximumStockQuantity": 1,
      //             "StoreId": 1,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 1,
      //             "PhysicalStockQuantity": 1,
      //             "MinimumStockQuantity": 1,
      //             "MaximumStockQuantity": 1,
      //             "StoreId": 33,
      //             "AllowSystemOverride": true
      //         },
      //         {
      //             "AvailableQuantity": 2,
      //             "PhysicalStockQuantity": 2,
      //             "MinimumStockQuantity": 2,
      //             "MaximumStockQuantity": 2,
      //             "StoreId": 57,
      //             "AllowSystemOverride": true
      //         }
      //     ]
      // })
      const products = convertProducts(
        response.data.Products,
        response.data.ProductUrls || response.data.Urls,
        response.data.ExtraFields
      )

      return products.filter((product) => product.isOrderable)
    },

    /*
     * Viewed products
     */

    async addViewedProduct(
      productId: number,
      brandId?: number
    ): Promise<boolean> {
      const response: AxiosResponse = await instance.post(`/${base}/viewed`, {
        productId,
        brandId,
      })
      return response.data.Success
    },

    async getViewedProducts(): Promise<Product[]> {
      const response: AxiosResponse = await instance.get(
        `/${base}/viewed?reload=true`,
        {
          params: {
            realtimeStock: true,
            fetchExtraFields: true,
          },
        }
      )
      if (!response.data.Success) return []

      const data = response.data
      return convertProducts(data.Products, data.ProductUrls, data.ExtraFields)
    },

    /*
     * Product sections
     */

    async getProductSelections(
      payload: ProductPageRequest
    ): Promise<ProductSelection[]> {
      const defaultParams: ProductPageRequest = {
        limit: 10,
        offset: 0,
        fetchExtraFields: false,
        realtimeStock: false,
      }
      const params = Object.assign(defaultParams, payload)

      const response: AxiosResponse = await instance.get(
        `/${base}/selections`,
        {
          params,
        }
      )
      if (!response.data.Success) return []

      const data = response.data
      return convertProductSelections(
        data.ProductSelections,
        data.ProductUrls,
        data.ExtraFields
      )
    },

    async addProductSelection(
      productId: number
    ): Promise<ProductSelection | null> {
      const response: AxiosResponse = await instance.post(
        `/${base}/selections`,
        {
          productId,
        }
      )
      if (!response.data.Success) return null
      return convertProductSelection(response.data.ProductSelection)
    },

    async removeProductSelection(productSelectionId: number): Promise<boolean> {
      const response: AxiosResponse = await instance.delete(
        `/${base}/selections/${productSelectionId}`
      )
      return !!response.data?.Success
    },

    async clearProductSelections(): Promise<boolean> {
      const response: AxiosResponse = await instance.post(
        `/${base}/selections/clear`
      )
      return !!response.data?.Success
    },

    /*
     * Product stocks
     */

    async getProductStocks(productId: number): Promise<ProductStock[]> {
      const response: AxiosResponse = await instance.get(
        `/${base}/${productId}/stock`
      )
      const stocks =
        response.data?.Stock?.map((stock: any) => convertStock(stock)) ?? []

      return stocks
    },

    async getStocks(
      productIds: number[]
    ): Promise<Dictionary<ProductStockRefresh>> {
      const response: AxiosResponse = await instance.post(`/${base}/stock`, {
        ids: productIds,
        fetchExtraFields: true,
      })

      const result: Dictionary<ProductStockRefresh> = {}
      Object.keys(response.data.Products).forEach((id) => {
        const data = response.data.Products[id]
        result[id] = {
          stock: convertProductStock(data.Stock),
          availibility: convertProductAvailibility(data.Availability),
        }
      })

      return result
    },
  }
}
