import { CustomerAddress, Customer } from './customer'
import { Product } from './product'
import { Store } from './store'
import { Shipping, ShippingEstimate } from './shipping'
import { Coupon, Voucher } from './promotion'

export interface Totals {
  subTotalInclTax: number
  subTotalExclTax: number

  deliveryCostInclTax?: number
  deliveryCostExclTax?: number
  palletCostExclTax?: number
  palletCostInclTax?: number
  shippingInclTax?: number
  shippingExclTax?: number

  totalInclTax: number
  totalExclTax: number

  discountInclTax?: number
  discountExclTax?: number
  oldTotalInclTax?: number
  oldTotalExclTax?: number

  totalTax: number
  redeemAmount?: number
  totalToPay: number
}

export enum CartLineAction {
  Add = 1,
  Update = 2,
  Delete = 3,
}

export interface DeliveryPackageInfo {
  carrierCode: string
  serviceLevelTimeCode: string
  serviceLevelOtherCode?: string
}

export interface DeliveryOptions {
  package: DeliveryPackageInfo
}

export interface CartLineChange {
  action?: CartLineAction
  productId: number
  quantity: number
}

export interface CartLineNote {
  productId: number
  notes: string
}

export interface CartLine {
  id?: number
  productId: number
  quantity: number
  productTitle?: string
  priceInclTax?: number
  priceExclTax?: number
  totalInclTax?: number
  totalExclTax?: number
  discountInclTax?: number
  discountExclTax?: number

  derived?: boolean
  oldPriceExclTax?: number
  oldPriceInclTax?: number
  priceChanged?: boolean

  shippingEstimate?: ShippingEstimate
  isValid?: boolean

  product?: Product

  notes?: string
}

export interface CartPayment {
  method?: string | null
  bank?: string | null
}

export enum ShippingMethod {
  Delivery = 'Delivery',
  Pickup = 'Pickup',
}

export enum ShippingMethodType {
  Delivery = 'CustomerAddress',
  Pickup = 'Pickup',
  Alternative = 'AlternativeAddress',
}

export interface Cart {
  lines: CartLine[]
  palletLines?: CartLine[]

  shippingMethod: ShippingMethod
  shippingMethodType: ShippingMethodType
  isDeliveryOrder: boolean

  deliveryCostExclTax?: number
  deliveryCostInclTax?: number
  deliveryCostPreviewExclTax: number
  deliveryCostPreviewInclTax: number
  palletCostExclTax?: number
  palletCostInclTax?: number
  palletQuantity?: number
  shippingInclTax?: number
  shippingExclTax?: number

  subTotalInclTax: number
  subTotalExclTax: number
  totalInclTax: number
  totalExclTax: number
  discountInclTax?: number
  discountExclTax?: number

  pointsToRedeem?: number

  customer?: Customer
  deliveryAddress?: CustomerAddress
  alternativeDeliveryAddress?: CustomerAddress
  store?: Store
  shipping?: Shipping
  payment?: CartPayment

  coupons?: Coupon[]
  vouchers?: Voucher[]
}

export interface ShippingTimeSlot {
  title: string
  timeFrom: string
  timeTo: string
}

export interface Shipment {
  date?: string
  price?: number
  shippingMethod: string
  timeFrom?: string
  timeTo?: string
  methodId: string | number
}

export enum CheckoutStep {
  Cart = 0,
  Shipping = 1,
  Payment = 2,
  Order = 3,
}

export interface CheckoutStatus {
  step: CheckoutStep
}

export interface CreateOrderRequest {
  reference?: string
  customerReference?: string
  remarks?: string
}

export interface CartSession {
  orderReference: string
  remark: string
  isAccept: boolean
}
