import { ContentPart, ContentPartMeta } from '~/types/content'
import {
  convertMenus,
  convertBanner,
  convertSiteBanner,
  convertCompanyServices,
  convertBlocks,
  convertBrand,
} from '~/lib/api/deserializers/content'
import { convertNewsItems } from '~/lib/api/deserializers/blog'
import { inRange } from '~/lib/utilities/datetime'

export const TypeMap = new Map<string, string>([
  ['Product', 'Commerce.Product'],
  ['ProductCategory', 'Commerce.ProductCategory'],
  ['Brand', 'Commerce.Brand'],
  ['Company', 'Contact.Company'],
  ['Content', 'Content.Article'],
  ['Tag', 'Content.Tag'],
  ['NewsItem', 'Content.NewsItem'],
  ['NewsCategory', 'Content.NewsCategory'],
])

export enum MenuKey {
  Top = 'topmenu',
  Customization = 'home-maatwerk',
  RepairRental = 'home-reparatie-verhuur',
  SmarterBusiness = 'home-slimmer-ondernemen',
}

const ServiceMenuKeys = new Set([
  MenuKey.Customization,
  MenuKey.RepairRental,
  MenuKey.SmarterBusiness,
])

const combineField = (req: any, field: string, value: string) => {
  if (req[field]) {
    req[field] += '|' + value
  } else {
    req[field] = value
  }
}

const createBasicMenuMeta = (
  part: ContentPart,
  category: string
): [ContentPart, ContentPartMeta] => {
  return [
    part,
    {
      request: (req) => {
        req.GetMenus = true
      },
      response: (article) => convertMenus(article[category]),
    },
  ]
}

const createBannerMeta = (
  part: ContentPart,
  categoryName: string
): [ContentPart, ContentPartMeta] => {
  return [
    part,
    {
      request: (req) => {
        combineField(req, 'BannerCategories', categoryName)
      },
      response: (article) => {
        const category = article.BannerCategories?.find(
          (category: any) => category.Name === categoryName
        )
        if (!category) return null

        return category.Banners.filter((banner: any) =>
          inRange(banner.PublicationDate, banner.RemovalDate)
        ).map(convertBanner)
      },
    },
  ]
}

const createBrandMeta = (
  part: ContentPart,
  categoryName: string
): [ContentPart, ContentPartMeta] => {
  return [
    part,
    {
      request: (req) => {
        combineField(req, 'BrandCategories', categoryName)
      },
      response: (article) => {
        const category = article.BrandCategories?.find(
          (category: any) => category.Name === categoryName
        )
        if (!category) return null
        return category.Brands.map(convertBrand)
      },
    },
  ]
}

export const PartMeta = new Map<ContentPart, ContentPartMeta>([
  // GetMenus
  createBasicMenuMeta(ContentPart.MainMenu, 'MainMenu'),
  createBasicMenuMeta(ContentPart.FooterMenu, 'FooterMenu'),
  createBasicMenuMeta(ContentPart.SubFooterMenu, 'SubFooterMenu'),

  // GetMenusByName
  [
    ContentPart.TopMenu,
    {
      request: (req) => {
        req.GetMenus = true
        combineField(req, 'GetMenusByName', MenuKey.Top)
      },
      response: (article) =>
        convertMenus(
          article.Menus?.find((menu: any) => menu.Name === MenuKey.Top)
            ?.Children
        ),
    },
  ],
  [
    ContentPart.ServiceMenus,
    {
      request: (req) => {
        req.GetMenus = true
        combineField(
          req,
          'GetMenusByName',
          Array.from(ServiceMenuKeys).join('|')
        )
      },
      response: (article) =>
        convertMenus(
          article.Menus.filter((menu: any) => ServiceMenuKeys.has(menu.Name))
        ),
    },
  ],

  // BrandCategories
  createBrandMeta(ContentPart.PopularBrands, 'popular-brands'),
  createBrandMeta(ContentPart.NewBrands, 'new-brands'),
  createBrandMeta(ContentPart.BrandsSlider, 'brandslider'),

  // BannerCategories
  [
    ContentPart.SiteBanners,
    {
      request: (req) => {
        combineField(req, 'BannerCategories', 'sitebanners')
      },
      response: (article) => {
        const siteBanners = article.BannerCategories?.find(
          (category: any) => category.Name === 'sitebanners'
        )
        if (siteBanners) {
          return siteBanners.Banners?.map(convertSiteBanner)
        }
      },
    },
  ],
  createBannerMeta(ContentPart.HomeBanners, 'home-banner'),
  createBannerMeta(ContentPart.HomeBannersRow, 'home-banner-row'),
  createBannerMeta(ContentPart.HomeUspBanners, 'home-usp-banners'),
  createBannerMeta(ContentPart.HomeThreeBlocks, 'home-3-blocks'),
  createBannerMeta(ContentPart.HomeAdvantages, 'home-advantages'),
  createBannerMeta(ContentPart.MainUsps, 'main-usps'),
  createBannerMeta(ContentPart.VestigingBanners, 'vestiging-banners'),
  createBannerMeta(ContentPart.CheckoutUSPs, 'checkout-usps'),

  // Banners
  [
    ContentPart.DiscountBanners,
    {
      request: (req, params) => {
        combineField(req, 'Banners', params?.DiscountBanners ?? '')
      },
      response: (article) => article.Banners.map(convertBanner),
    },
  ],

  // Others
  [
    ContentPart.CompanyServices,
    {
      request: () => {},
      response: (article) => convertCompanyServices(article),
    },
  ],
  [
    ContentPart.WildPageMenu,
    {
      request: (req) => {
        req.GetChilds = true
      },
      response: (article) => convertMenus(article.Children),
    },
  ],
  [
    ContentPart.Blocks,
    {
      request: (req) => {
        req.GetBlocks = true
      },
      response: (article) => convertBlocks(article.Blocks),
    },
  ],
  [
    ContentPart.NewsItems,
    {
      request: () => {},
      response: (article) => convertNewsItems(article.NewsItems),
    },
  ],
])
