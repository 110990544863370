import { useContext, useRouter, ref } from '@nuxtjs/composition-api'
import { useLoading } from '../ui/useLoading'
import { useAuth } from '../useAuth'
import { ProductReview } from '~/types/product'

export enum ReviewSorting {
  NewestFirst = 'newestFirst',
  OldestFirst = 'oldestFirst',
  MostStars = 'mostStars',
  FewestStars = 'fewestStars',
}

export const DEFAULT_LIMIT = 3

const addProductReviewLoading = useLoading()
const getProductReviewsLoading = useLoading()
const reviews = ref<ProductReview[]>([])
const offset = ref<number>(0)
const limit = ref<number>(DEFAULT_LIMIT)
export const useProductReview = () => {
  const { app } = useContext()
  const router = useRouter()
  const { ensureAuthed } = useAuth()

  const goToReview = (
    productUrl: string,
    productId: number,
    productName: string
  ) => {
    if (!productUrl || !productId || !productName) return
    ensureAuthed(() => {
      router.push(
        app.localePath({
          name: 'review-slug',
          params: { slug: productUrl },
          query: {
            productId: productId.toString(),
            productName,
          },
        })
      )
    })
  }

  const getProductReviews = (
    productId: number,
    sorting = ReviewSorting.NewestFirst
  ) => {
    return getProductReviewsLoading.scope(async () => {
      reviews.value = await app.$api.product.getReviews({
        productId,
        sorting,
      })
    })
  }

  const initProductReviews = async (productId: number) => {
    offset.value = 0
    limit.value = DEFAULT_LIMIT
    await getProductReviews(productId)
  }

  const addProductReview = async (productId: number, review: ProductReview) => {
    let success = false
    await addProductReviewLoading.scope(async () => {
      success = await app.$api.product.addReview(productId, review)
    })
    return success
  }

  return {
    addProductReviewLoading: addProductReviewLoading.value,
    getProductReviewsLoading,
    limit,
    offset,
    reviews,

    initProductReviews,
    addProductReview,
    goToReview,
  }
}
