import { BrandSuggestion, Suggestion } from '~/types/sugesstion'

export const convertSuggestion = (data: any): Suggestion => {
  return {
    name: data.PathTitle ? data.PathTitle + ' > ' + data.Title : data.Title,
    url: data.PathUrl ? data.PathUrl + '/' + data.Url : data.Url,
  }
}

export const convertBrandSuggestion = (data: any): BrandSuggestion => {
  return {
    ...convertSuggestion(data),
    text: data.Text,
    summary: data.Summary,
  }
}
