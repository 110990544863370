// eslint-disable-next-line import/no-duplicates
import { format, setDay } from 'date-fns'
// eslint-disable-next-line import/no-duplicates
import { nl, enGB, fr } from 'date-fns/locale'
import { ISO8601DurationRegex } from './regex'
import { RegularTime } from '~/types/store'
import { trimString } from '~/lib/utilities'

const LANGUAGE_CODE_DICTIONARY = {
  NL: nl,
  FR: fr,
  EN: enGB,
} as const

export const getUTCDate = (
  _date: string | Date,
  // "isDeep = true" means it also need to get hours, minutes and seconds
  isDeep = true
) => {
  const date = new Date(_date)
  if (isDeep) {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  }

  return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
}

export const isValidDateString = (date: string) => {
  // '0001-01-01T00:00:00.0000000' means empty date
  return !!date && date !== '0001-01-01T00:00:00.0000000'
}

export const getFullDateTime = (date: string, fullTime?: string) => {
  if (!isValidDateString(date)) return date

  const fullDateTime = fullTime
    ? `${format(getUTCDate(date), 'yyyy/MM/dd').replace(
        /\//g,
        '-'
      )}T${fullTime}.0000000+00:00`
    : date
  return fullDateTime
}

export const inRange = (
  _startDate?: string,
  _endDate?: string,
  // "isDeep = true" means it also need to compare hours, minutes and seconds
  isDeep = false
): boolean => {
  let now = new Date()
  if (!isDeep) {
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  }
  const startDate =
    _startDate && isValidDateString(_startDate)
      ? getUTCDate(_startDate, isDeep)
      : undefined
  const endDate =
    _endDate && isValidDateString(_endDate)
      ? getUTCDate(_endDate, isDeep)
      : undefined

  if (startDate && !endDate) {
    return startDate <= now
  }
  if (!startDate && endDate) {
    return now <= endDate
  }
  if (startDate && endDate) {
    return startDate <= now && now <= endDate
  }
  return true
}

export const formatTimeString = (timeString: string) => {
  // format 1900 to 19:00
  return `${timeString.slice(0, 2)}:${timeString.slice(2)}`
}

export const parseISO8601Duration = (
  duration: string,
  option?: {
    format?: 'hh:mm:ss' | 'hh:mm' | 'hh.mm.ss' | 'hh.mm'
    padLastSecond?: boolean
  }
): string => {
  const { format = 'hh:mm', padLastSecond = false } = option || {}
  const [, hours, mins, secs] = duration.match(ISO8601DurationRegex) || []
  // support two kinds of delimiter (':' and '.')
  let delimiter = ':'
  if (format?.indexOf('.') !== -1) {
    delimiter = '.'
  }
  const formatArr = format.split(delimiter)
  const fullTimeArr: string[] = []
  if (formatArr?.includes('hh')) {
    fullTimeArr.push(hours || '00')
  }
  if (formatArr?.includes('mm')) {
    fullTimeArr.push(mins || '00')
  }
  if (formatArr?.includes('ss')) {
    fullTimeArr.push(secs || (padLastSecond ? '59' : '00'))
  }

  return fullTimeArr.length
    ? fullTimeArr
        .map((num) => (num.length < 2 ? `0${num}` : num))
        .join(delimiter)
    : ''
}

export const getWeekDay = (day: string): number => {
  const WEEK_DICTIONARY = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  }
  return WEEK_DICTIONARY[day] ?? -1
}

export const localeWeekDay = (
  day: number,
  languageCode: keyof typeof LANGUAGE_CODE_DICTIONARY = 'NL'
) => {
  if (day < 0 || day > 6) return ''

  return format(setDay(new Date(), day), 'EEEE', {
    locale: LANGUAGE_CODE_DICTIONARY[languageCode],
  })
}
export const getRegularTime = (day: number, regularTimes: RegularTime[]) => {
  return regularTimes.find((d) => d.day === day) ?? null
}

export const getTodayRegularTime = (regularTimes: RegularTime[]) => {
  const day = new Date().getDay()
  return getRegularTime(day, regularTimes)
}

export const getCurrentTimeString = () => {
  const now = new Date()
  const hh = trimString(now.getHours().toString(), 2)
  const mm = trimString(now.getMinutes().toString(), 2)
  const ss = trimString(now.getSeconds().toString(), 2)
  return `${hh}:${mm}:${ss}`
}

export const getSecFromTime = (time: string) => {
  if (!time) return 0
  const timeArr = time.split(':').map((i) => Number(i))
  const h = timeArr[0] ?? 0
  const m = timeArr[1] ?? 0
  const s = timeArr[2] ?? 0
  return h * 3600 + m * 60 + s
}

export const isAfterTime = (time: string, timeToCompare: string) => {
  return getSecFromTime(time) > getSecFromTime(timeToCompare)
}

export const isWithinTime = (
  time: string,
  startTime: string,
  endTime: string
) => {
  const timeSec = getSecFromTime(time)
  return (
    timeSec >= getSecFromTime(startTime) && timeSec <= getSecFromTime(endTime)
  )
}

export const getNextYearDate = (date = new Date()) => {
  return new Date(new Date().setFullYear(date.getFullYear() + 1))
}

export enum Quarter {
  q1 = 1,
  q2 = 4,
  q3 = 7,
  q4 = 10,
}

export const monthsNL = [
  'januari',
  'februari',
  'maart',
  'april',
  'mei',
  'juni',
  'juli',
  'augustus',
  'september',
  'oktober',
  'november',
  'december',
]

export const monthsEN = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
