import { computed } from '@nuxtjs/composition-api'
import { useConfig } from '../useConfig'
import { useCustomer } from '../useCustomer'
import { useAuth } from '../useAuth'
import { ProductDiscount } from '~/types/product'
import project from '~/project.config.js'
import { ReactiveValue } from '~/types/common'

const MATCH_PRICE_LIST_ID = project.product?.discount?.matchPriceListId ?? false

export const useProductDiscounts = (
  discounts: ReactiveValue<ProductDiscount[]>
) => {
  const { config } = useConfig()
  const { authed } = useAuth()
  const { customer } = useCustomer()

  const priceListId = computed(() =>
    authed.value
      ? customer.value?.priceListId
      : config.value?.defaultPriceListId
  )
  const filteredDiscounts = computed(() => {
    if (MATCH_PRICE_LIST_ID) {
      if (discounts.value.length && priceListId.value) {
        return discounts.value.filter(
          (discount) => discount.priceListId === priceListId.value
        )
      } else {
        return []
      }
    }

    return discounts.value
  })

  return {
    filteredDiscounts,
  }
}
