import { computed } from '@nuxtjs/composition-api'
import { injectWebNode } from '~/composables/useWebNode'
import { ContentPart } from '~/types/content'
import { useContent } from '~/composables/useContent'

// As @menno mentioned, the brand webnode will be deprectated and use brand own entities
export const BrandWebNodeCode = 'MERKEN'

export const useMenu = () => {
  const { root, loading } = injectWebNode()

  const { menus } = useContent()

  const topMenu = computed(() => menus.value?.[ContentPart.TopMenu] ?? [])

  const footerMenu = computed(() => menus.value?.[ContentPart.FooterMenu] ?? [])

  const mainMenu = computed(() => menus.value?.[ContentPart.MainMenu] ?? [])

  const categories = computed(() =>
    root.value?.children?.filter((item) => item.code !== BrandWebNodeCode) // filter out brand webnode
  )

  return {
    root,
    loading,
    categories,
    topMenu,
    footerMenu,
    mainMenu,
  }
}
