import { Dictionary } from 'ramda'
import { Brand } from './brand'
import { Media } from './common'
import { RedirectToUrl } from './search'
import { Store } from './store'
import { AheadWord } from './sugesstion'
import { WebNode, WebNodeStats } from './webNode'

export interface ProductStock {
  storeId: number
  quantity: number
  store?: Store
}

export interface ProductAvailabilityInServer {
  isOrderable: boolean
  inStock: boolean
  stockQuantity: number
  maxStockEnabled: boolean
  isDeliverable: boolean
  isPickupable: boolean
}

export interface ProductStockRefresh {
  stock: ProductStock[]
  availibility: ProductAvailabilityInServer
}

export interface ProductDiscountLevel {
  id: number
  quantity: number
  priceInclTax: number
  priceExclTax: number
  save: number
  unitPriceExclTax?: number
  unitPriceInclTax?: number
}

export interface ProductDiscount {
  id?: number
  name: string
  category?: string
  img?: string
  startDate?: string
  endDate?: string
  priceListId?: number
}

export interface ProductField {
  code: string
  label: string
  value: string | number | string[]
}

export interface ProductMemoFile {
  fileName: string
  memoId: number
}

export enum WebshopBannerType {
  Label = 'label',
  Tag = 'tag',
}

export interface WebshopBanner {
  name: string
  type: WebshopBannerType
}

export interface ProductLabel {
  name?: string
  key: string
  img?: string
  order?: number
}

export enum ProductAvailability {
  NotOrderable = 'NotOrderable',
  InStock = 'InStock',
  OutOfStock = 'OutOfStock',
}

export enum ProductConnectionType {
  Combination = 'Combination',
  Same = 'Same',
  Alternative = 'Alternative',
  Interesting = 'Interesting',
  Replacement = 'Replacement',
  Bundle = 'Bundle',
  Color = 'Color',
  Size = 'Size',
  Variant = 'Variant',
}

export enum ProductType {
  Product = 'Product',
  Variant = 'Variant',
}

export enum ProductVariantType {
  Color = 'Color',
  Size = 'Size',
}

export interface RatingGroupItem {
  count: number
  rating: number
}

export interface Product extends ProductAvailabilityInServer {
  id: number
  name: string
  number: string
  numberTrimmed: string
  scanCode?: string
  url?: string

  // variant
  variantType?: ProductConnectionType
  size?: string
  colorHex?: string
  colorName?: string
  variants?: Product[]

  // Rich text
  description?: string
  ingredients?: string
  usageDescription?: string

  // Image and media
  img?: string
  gallery?: Media[]
  discountImg?: string
  label?: ProductLabel

  // Pricing
  priceInclTax: number
  priceExclTax?: number
  oldPriceInclTax?: number
  oldPriceExclTax?: number
  taxRate?: number

  // Unit attributes
  unitPriceInclTax?: number
  unitPriceExclTax?: number
  unitPerProduct?: number
  unit?: string

  // Status
  isActive: boolean
  isVirtual: boolean
  isViewable: boolean
  isValidInCart: boolean

  // availability
  maxInCart?: number

  // Relation
  brand?: Brand
  webNode?: WebNode
  activeWebNodes?: WebNode[]
  stock?: ProductStock[]
  discounts?: ProductDiscount[]
  discount?: ProductDiscount
  moreLessLevels?: ProductDiscountLevel[]

  // Specifitions
  fields?: ProductField[]

  // Downlaod files
  files?: ProductMemoFile[]

  // Review stats
  rating?: number
  reviewCount?: number
  ratings?: RatingGroupItem[]

  // safetyLogos?: string[]
  // deliveryType?: ProductDeliveryType | null
  // deliverySize?: ProductDeliverySize
  type?: ProductType
  tags?: ProductLabel[]
  webshopBanner?: WebshopBanner
}

export interface ProductReview {
  rating: number
  content: string
  date: Date
  firstName: string
}

/*
 * Product list
 */

export interface ProductSortOption {
  label: string
  field: string
  order: string
  value: string
}

export enum ProductSortingFields {
  CreationDate = 'CreationDate',
  Relevance = 'WeightingFactor',
}

export interface ProductLimitOption {
  label: string
  value: number
}

export interface ProductFacetValue {
  label: string
  count: number
  value: string
  checked: boolean
}

export interface ProductFacet {
  field: string
  label: string
  values: ProductFacetValue[]
}

export interface ProductCondition {
  field: string
  value: string | number
  label?: string
}

export interface ProductConditionGroup {
  field: string
  label: string
  values: string[]
}

export enum ProductListMode {
  Navigation = 0,
  Filter = 1,
}

export enum ListType {
  Category = 'Category',
  Brand = 'Brand',
  Search = 'Search',
}

export interface ProductListOptions {
  listType?: ListType
  category?: WebNode
  prefilters?: ProductCondition[]
  limit?: number
  limitOptions?: ProductLimitOption[]
  sortOptions?: ProductSortOption[]
  // Use Map<string, string> before, but encounter type changed in client-side after server-side fetched
  facetLabels?: Dictionary<string>
  webNodeMode?: ProductListMode
  brandMode?: ProductListMode
  query?: string
}

export interface Range {
  min: number
  max: number
}

export interface PriceRange {
  incl: Range
  excl?: Range
}

export interface ProductSearchRequest {
  query?: string
  webNodeId?: number
  webNodeIds?: number[]
  filters?: Map<string, string[]>
  limit?: number
  offset?: number
  sortBy?: string
  priceRange?: Range
}

export interface ProductSearchResult {
  products: Product[]
  total: number
  facets: ProductFacet[]
  webNodeStats?: WebNodeStats[]
  priceRange?: PriceRange
  popularCategories?: WebNode[]
  redirectToUrl?: RedirectToUrl
  alternativeSuggestions?: AheadWord[]
  popularBrands?: Brand[]
}

export enum FilterFields {
  BrandUrl = 'BrandUrl',
  BrandTitle = 'BrandTitle',
  Price = 'GrossPrice',
  Prices = 'Prices',
  MinPrice = 'MinPrice',
  MaxPrice = 'MaxPrice',
  WebNodeId = 'WebNodeId',
  WebNodeIds = 'WebNodeIds',
  Query = 'Query',
  Page = 'Page',
  Limit = 'Limit',
}

export interface ProductSelection {
  id: number
  productId: number
  product: Product
}

export interface ProductPageRequest {
  limit?: number
  offset?: number
  realtimeStock?: boolean
  fetchExtraFields?: boolean
}

export interface GetProductParams {
  webNodeId?: string
}
