import {
  useContext,
  readonly,
  InjectionKey,
  provide,
  inject,
  computed,
  ssrRef,
} from '@nuxtjs/composition-api'
import { useLoading } from './ui/useLoading'
import { WebNode } from '@/types/webNode'

const setWebNodeNamesMap = (map: Map<number, string>, webNode: WebNode) => {
  map.set(webNode.id, webNode.name)
  webNode.children?.forEach((item) => {
    if (map.has(item.id)) return
    setWebNodeNamesMap(map, item)
  })
}

export const getWebNodeNamesMap = (webNode: WebNode) => {
  const map = new Map<number, string>()
  setWebNodeNamesMap(map, webNode)
  return map
}

export const useWebNode = () => {
  const { app } = useContext()
  const root = ssrRef<WebNode | null>(null)
  const loading = useLoading()

  const webNodeNamesMap = computed(
    () => root.value && getWebNodeNamesMap(root.value)
  )

  const ensureRoot = async (descendantLevel?: number) => {
    if (root.value) return

    await loading.scope(async () => {
      if (root.value) return
      root.value = await app.$api.webNode.getRoot(descendantLevel)
    })
  }

  const ensureNode = async (node: WebNode) => {
    if (node.loaded) return
    const loadedNode = await app.$api.webNode.getMainById(node.id as number, 2)
    node.children = loadedNode?.children
    node.loaded = true
  }

  return {
    loading: loading.value,
    root: readonly(root),
    webNodeNamesMap,
    ensureRoot,
    ensureNode,
  }
}

export const webNodeProviderKey: InjectionKey<ReturnType<typeof useWebNode>> =
  Symbol('Provider:WebNode')

export const provideWebNode = () => {
  const result = useWebNode()

  provide(webNodeProviderKey, result)
}

export const injectWebNode = () => {
  const result = inject(webNodeProviderKey)

  if (result == null) {
    throw new Error('webNode provider not set')
  }

  return result
}
