import { AxiosInstance, AxiosResponse } from 'axios'
import { Context } from '@nuxt/types'
import {
  convertVoucher,
  convertCoupon,
} from '~/lib/api/deserializers/promotion'
import { VoucherByCodeResult } from '~/types/promotion'

export default function (instance: AxiosInstance, { app }: Context) {
  const base = 'api/aspos/customer/vouchers'

  return {
    async getVoucherByCode(voucherCode: string): Promise<VoucherByCodeResult> {
      const response: AxiosResponse = await instance.get(
        `/${base}/code/${voucherCode}`
      )

      if (!response.data.Success) {
        return {
          message:
            response.data.message ?? app.i18n.t('message.promotion.code.error'),
        }
      }

      const result: VoucherByCodeResult = {}
      if (response.data.Voucher) {
        result.voucher = convertVoucher(response.data.Voucher)
      } else {
        result.coupon = convertCoupon(response.data.Coupon)
      }

      return result
    },
  }
}
