export const postalCodeRegex = {
  NL: /^\d{4}\s{0,1}[A-Za-z]{2}$/,
  BE: /^\d{4}$/,
  others: /^[a-zA-Z0-9]{1,10}$/,
}
export const emailRegex = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
export const ISO8601DurationRegex = /P(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?$/

export const isPostalCode = (val: string) =>
  postalCodeRegex.NL.test(val) || postalCodeRegex.BE.test(val)
export const isEmail = (val: string) => emailRegex.test(val)
export const isISO8601Duration = (val: string) => ISO8601DurationRegex.test(val)
