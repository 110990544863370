var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"search-panel text-base shadow-lg"},[_c('div',{staticClass:"relative"},[(_vm.hasResult)?_c('button',{staticClass:"absolute -right-2 -top-2 text-pfm-secondary hidden lg:block",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('BaseIcon',{staticClass:"w-6 h-6",attrs:{"name":"pfm-times-circle"}})],1):_vm._e(),_vm._v(" "),(_vm.trimedKeyword)?_c('div',{class:{ 'px-4 py-2  max-h-50vh overflow-auto': _vm.hasResult },attrs:{"data-scroll-lock-scrollable":""}},[(_vm.loading)?_c('div',{staticClass:"px-4 py-2"},[_vm._v("\n        "+_vm._s(_vm.$t('common.text.loading'))+"\n      ")]):_vm._e(),_vm._v(" "),(_vm.suggestions)?[(_vm.hasResult)?_c('p',{staticClass:"border-b-2 border-b-pfm-grey-400 pb-4 uppercase mb-4"},[_vm._v("\n          "+_vm._s(_vm.$t('searchPanel.searchResult'))+"\n        ")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"md:grid md:grid-cols-2 md:gap-x-8"},[(_vm.suggestions.products)?_c('div',{staticClass:"col-span-1"},[_c('h4',{staticClass:"border-b-2 border-b-black capitalize text-black font-bold"},[_vm._v("\n              "+_vm._s(_vm.suggestions.products.name)+"\n            ")]),_vm._v(" "),_c('ul',_vm._l((_vm.suggestions.products.suggestions),function(product,index){return _c('li',{key:("product-" + index)},[_c('NuxtLink',{staticClass:"flex items-center py-2 group",attrs:{"to":_vm.localePath(
                      product.url
                        ? {
                            name: 'product-detail-slug',
                            params: { slug: product.url },
                          }
                        : { name: 'index' }
                    )},nativeOn:{"click":function($event){_vm.hideSearchPanel()
                    _vm.closeSidebar()}}},[_c('BaseIcon',{staticClass:"w-3.5 h-3.5 mr-1 text-pfm-grey-600",attrs:{"name":"pfm-search"}}),_vm._v(" "),(product.img)?_c('div',{staticClass:"aspect-square w-10 flex-shrink-0 mr-2 hidden lg:block"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(product.img),expression:"product.img"}],staticClass:"w-full h-full object-contain",attrs:{"width":"90","height":"90","alt":product.name}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"flex-1 max-w-3/4"},[(product.brand)?_c('p',[_vm._v(_vm._s(product.brand.name))]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"line-clamp-2 leading-none group-hover:underline",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(product.name))}})])],1)],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.suggestions)?_c('div',[(_vm.suggestions.categories)?_c('div',{staticClass:"mb-2"},[_c('h4',{staticClass:"border-b-2 border-b-black capitalize text-black font-bold"},[_vm._v("\n                "+_vm._s(_vm.suggestions.categories.name)+"\n              ")]),_vm._v(" "),_c('ul',{staticClass:"py-1"},_vm._l((_vm.suggestions.categories
                    .suggestions),function(category,index){return _c('li',{key:("category-" + index),staticClass:"py-1.5"},[_c('NuxtLink',{staticClass:"flex items-start w-full hover:underline",attrs:{"to":_vm.localePath(
                        category.url
                          ? {
                              name: 'product-lister-all',
                              params: { pathMatch: category.url },
                            }
                          : { name: 'index' }
                      )},nativeOn:{"click":function($event){_vm.hideSearchPanel()
                      _vm.closeSidebar()}}},[_c('BaseIcon',{staticClass:"w-3.5 h-3.5 mt-px mr-1 text-pfm-grey-600",attrs:{"name":"pfm-search"}}),_vm._v(" "),_c('div',{staticClass:"line-clamp-3 leading-none",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(category.name))}})],1)],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.suggestions.brands)?_c('div',{staticClass:"mb-2"},[_c('h4',{staticClass:"border-b-2 border-b-black capitalize text-black font-bold"},[_vm._v("\n                "+_vm._s(_vm.suggestions.brands.name)+"\n              ")]),_vm._v(" "),_c('ul',{staticClass:"py-1"},_vm._l((_vm.suggestions.brands.suggestions),function(brand,index){return _c('li',{key:("brand-" + index),staticClass:"py-1.5"},[_c('NuxtLink',{staticClass:"flex items-start w-full hover:underline",attrs:{"to":_vm.localePath(
                        brand.url
                          ? {
                              name: 'brand-lister-all',
                              params: { pathMatch: brand.url },
                            }
                          : { name: 'index' }
                      )},nativeOn:{"click":function($event){_vm.hideSearchPanel()
                      _vm.closeSidebar()}}},[_c('BaseIcon',{staticClass:"w-3.5 h-3.5 mt-px mr-1 text-pfm-grey-600",attrs:{"name":"pfm-search"}}),_vm._v(" "),_c('div',{staticClass:"line-clamp-2 leading-none",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(brand.name))}})],1)],1)}),0)]):_vm._e(),_vm._v(" "),(_vm.suggestions.aheadWords)?_c('div',{staticClass:"mb-2"},[_c('h4',{staticClass:"border-b-2 border-b-black capitalize text-black font-bold"},[_vm._v("\n                "+_vm._s(_vm.suggestions.aheadWords.name)+"\n              ")]),_vm._v(" "),_c('ul',{staticClass:"py-1"},_vm._l((_vm.suggestions.aheadWords
                    .suggestions),function(aheadWord,index){return _c('li',{key:("aheadWord-" + index),staticClass:"py-1.5"},[_c('NuxtLink',{staticClass:"flex items-start w-full hover:underline",attrs:{"to":_vm.localePath(
                        aheadWord.name
                          ? {
                              name: 'search-slug',
                              params: { slug: aheadWord.name },
                            }
                          : { name: 'index' }
                      )},nativeOn:{"click":function($event){_vm.hideSearchPanel()
                      _vm.closeSidebar()}}},[_c('BaseIcon',{staticClass:"w-3.5 h-3.5 mt-px mr-1 text-pfm-grey-600",attrs:{"name":"pfm-search"}}),_vm._v(" "),_c('div',{staticClass:"line-clamp-2 leading-none",domProps:{"innerHTML":_vm._s(_vm.highlightKeyword(aheadWord.name))}})],1)],1)}),0)]):_vm._e()]):_vm._e()])]:_vm._e()],2):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }