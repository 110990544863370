import { Media } from './common'
import { Address, Location } from './geo'

export interface OpeningTime {
  day: number | Date
  openTime?: string
  closeTime?: string
}

export interface RegularTime extends OpeningTime {
  day: number
}

export interface HolidayTime extends OpeningTime {
  day: Date
}

export interface OpeningTimes {
  regularTimes: RegularTime[]
  extraOpeningDays?: string
  extraClosingDays?: string
}

export interface Manager {
  firstName: string
  lastName: string
  image?: string
}

export interface StoreNews {
  date: Date | string
  title: string
  description: string
}

export interface Store extends Address {
  id: number
  name: string
  code?: string
  type?: string
  url?: string

  image?: string
  gallery?: Media[]
  email?: string
  phoneNumber?: string

  location?: Location

  manager?: Manager
  openingTimes?: OpeningTimes

  onFleetId?: string

  webshopIsPickupStore?: boolean

  webshopIsShowOnPDP?: boolean
}

export interface StoreParams {
  distanceFromLatitude?: number
  distanceFromLongitude?: number
  storeTags?: Array<string> | string
}

export enum OrderBy {
  Distance = 1,
  Alphabetic = 2,
}

export enum FilterOption {
  Opened = 'opened',
  AfterDusk = 'afterDusk',
  Sunday = 'sunday',
}

export interface FilterConfig {
  [FilterOption.Opened]: boolean
  [FilterOption.AfterDusk]: boolean
  [FilterOption.Sunday]: boolean
}

export enum StockStatus {
  NoStock = '#c61938',
  LimitedStock = '#fdc300',
  EnoughStock = '#3aaa35',
  Default = '#ffffff',
  CheckoutNoStock = '#000000',
  CheckoutEnoughStock = '#f4a8b2',
}
