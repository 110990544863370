var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quantity-input inline-flex bg-pfm-primary text-white h-8 relative",class:[
    _vm.readonly
      ? 'pointer-events-none bg-black bg-opacity-10 opacity-60'
      : 'bg-white',
    {
      'w-full': _vm.block,
      'pr-5': _vm.contronlPosition !== 'default',
    } ]},[(!_vm.readonly)?_c('button',{staticClass:"btn flex items-center justify-center flex-shrink-0",class:[
      _vm.contronlPosition === 'default'
        ? 'w-8'
        : 'w-5 h-5 absolute bottom-0 right-0 border-t border-white',
      {
        'opacity-60 cursor-not-allowed': _vm.isDecreaseDisabled,
      } ],attrs:{"type":"button","disabled":_vm.readonly || _vm.isDecreaseDisabled,"data-testid":_vm.dataTestid.decrease},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDecrease.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"w-2 h-2",attrs:{"name":"pfm-remove"}})],1):_vm._e(),_vm._v(" "),_c('input',{ref:"inputEl",staticClass:"focus:outline-none appearance-none-number text-center text-xl w-full h-full outline-none bg-transparent p-2",attrs:{"type":_vm.inputType,"maxlength":_vm.maxLength,"readonly":_vm.readonly,"data-testid":_vm.dataTestid.input},domProps:{"value":_vm.value},on:{"focus":_vm.onFocus,"blur":function($event){_vm.isFocus = false},"change":function($event){_vm.check(Number($event.target.value))},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return _vm.onIncrease.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return _vm.onDecrease.apply(null, arguments)}]}}),_vm._v(" "),(!_vm.readonly)?_c('button',{staticClass:"btn flex items-center justify-center flex-shrink-0",class:[
      _vm.contronlPosition === 'default'
        ? 'w-8 '
        : 'w-5 h-5 absolute top-0 right-0 border-b border-white',
      {
        'opacity-60 cursor-not-allowed': _vm.isIncreaseDisabled,
      } ],attrs:{"type":"button","disabled":_vm.readonly || _vm.isIncreaseDisabled,"data-testid":_vm.dataTestid.increase},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onIncrease.apply(null, arguments)}}},[_c('BaseIcon',{staticClass:"w-2 h-2",attrs:{"name":"pfm-add"}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }