export default {
  customer: {
    contactCentric: false,
  },
  product: {
    details: {
      commonConnectionTypes: ['Interesting'],
      VariantConnectionTypes: ['Size', 'Color', 'Variant'],
    },
    viewableExcludeTypes: ['ProductVoucher'],
    discount: {
      matchPriceListId: true,
    },
  },
  checkout: {
    payment: {
      provider: 'Ogone',
    },
  },
  store: {
    availableStoreExcludeIds: [1],
  },
  axios: {
    urlsToCache: [
      // '/api/vm/u',
      // '/api/sp/config',
      '/api/aspos/common/config',
      '/api/aspos/webnodes/main/root',
    ],
  },
}
