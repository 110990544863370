import { render, staticRenderFns } from "./AlphabetMegaMenu.vue?vue&type=template&id=24ba933a&scoped=true&"
import script from "./AlphabetMegaMenu.vue?vue&type=script&lang=ts&"
export * from "./AlphabetMegaMenu.vue?vue&type=script&lang=ts&"
import style0 from "./AlphabetMegaMenu.vue?vue&type=style&index=0&id=24ba933a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24ba933a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseIcon: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/16/a/NuxtApp/components/base/Icon.vue').default,BaseOverlay: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/16/a/NuxtApp/components/base/Overlay.vue').default})
