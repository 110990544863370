import {
  Ref,
  computed,
  readonly,
  ssrRef,
  useContext,
} from '@nuxtjs/composition-api'
import { useConfig } from '../useConfig'
import { Product, ProductAvailability } from '~/types/product'
import { ReactiveValue } from '~/types/common'
import { checkOrderable, getMaxQuantity } from '~/lib/api/deserializers/product'

export enum ProductEvents {
  Search = 'product/search',
  ViewList = 'product/viewList',
  Click = 'product/click',
  ViewDetails = 'product/viewDetails',
}

export const useProduct = (
  productRef: ReactiveValue<Product>,
  cartQuantityRef?: Ref<number>
  // inputQuantityRef?: Ref<number>
) => {
  // inputQuantityRef ??= cartQuantityRef
  const { config } = useConfig()

  // Availability
  const isOrderable = computed(() => checkOrderable(productRef.value))
  const webStock = computed(() => getStock(config.value?.webStoreId))
  const availability = computed(() => {
    if (!isOrderable.value) return ProductAvailability.NotOrderable

    if (productRef.value.inStock) return ProductAvailability.InStock

    return ProductAvailability.OutOfStock
  })
  // const deliveryStock = computed(() => webStock.value)
  // const pickupStock = computed(() => webStock.value)
  const maxQuantity = computed(() =>
    getMaxQuantity(productRef.value.stockQuantity, productRef.value.maxInCart)
  )
  const maxQuantityInc = computed(() => {
    const quantityInc = maxQuantity.value - (cartQuantityRef?.value ?? 0)
    return quantityInc > 0 ? quantityInc : 0
  })

  const getStock = (storeId?: number) => {
    const stock = productRef.value.stock
    if (!storeId || !stock) return 0

    const store = stock.find((st) => st.storeId === storeId)
    if (!store) return 0

    return store.quantity > 0 ? store.quantity : 0
  }

  return {
    isOrderable,
    maxQuantity,
    maxQuantityInc,
    webStock,
    availability,
    // deliveryStock,
    // pickupStock,

    getStock,
  }
}

export const useProductStockRefresh = (
  productParam: Ref<Product[]> | Ref<Product | null>
) => {
  const products = computed(() => {
    const asProducts = productParam.value as Product[]
    if (typeof asProducts?.length === 'undefined') {
      if (productParam.value === null) {
        return []
      } else {
        return [productParam.value as Product]
      }
    } else {
      return asProducts
    }
  })
  const { app } = useContext()

  const productLoadedFromServer = ssrRef<boolean>(false)

  const onProductsLoaded = () => {
    productLoadedFromServer.value = process.server
  }

  const refreshStocks = async (options?: any) => {
    options = Object.assign(
      {
        forceRefresh: false,
      },
      options
    )

    if (!products.value?.length) return
    if (!options.forceRefresh && !productLoadedFromServer.value) return

    const stockMap = await app.$api.product.getStocks(
      products.value
        .filter((product) => !product.isVirtual)
        .map((product) => product.id)
    )
    if (Object.keys(stockMap)) {
      const newProducts = [...products.value].map((product) => {
        const stockRefresh = stockMap[product.id.toString()]
        if (stockRefresh) {
          product.stock = stockRefresh.stock
          Object.assign(product, stockRefresh.availibility)
        }

        // { ...product } to clone shallowly
        return {
          ...product,
        }
      })
      // cover origin fields for products
      productParam.value = Array.isArray(productParam.value)
        ? newProducts
        : newProducts[0]
    }
  }

  return {
    productLoadedFromServer: readonly(productLoadedFromServer),

    onProductsLoaded,
    refreshStocks,
  }
}
