const pagesToScrollAsync = new Set([
  'product-lister-all',
  'search-slug',
  'brand-lister-all',
])

const shouldScrollAsync = (route) => {
  const routeName = route.name?.split('___')[0]
  if (!routeName || !pagesToScrollAsync.has(routeName)) {
    return false
  }
  return true
}

export default (to, from, savedPosition) => {
  if (!process.client || from.fullPath === to.fullPath) return null

  const defaultPosition = to.hash
    ? { selector: decodeURIComponent(to.hash) }
    : { x: 0, y: 0 }
  if (!shouldScrollAsync(to)) return defaultPosition

  return new Promise((resolve) => {
    window.$nuxt.$once('readyToScroll', () => {
      let position = null
      if (savedPosition) {
        position = savedPosition
      } else {
        position = defaultPosition
      }
      resolve(position)
    })
  })
}
