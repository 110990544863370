import { ref } from '@nuxtjs/composition-api'
import { Dictionary } from 'ramda'

// list available modal, mapping the component name
export enum Modals {
  AddToCart = 'ModalAddToCart',
  Stock = 'ModalStock',
  Ingredients = 'ModalIngredients',
  ProductSizeSelection = 'ModalProductSizeSelection',
  Address = 'ModalAddress',
  Gift = 'ModalGift',
  AddProductReview = 'ModalAddProductReview',
}

interface Modal {
  name: string | null
  attrs: Dictionary<any>
}

const state = ref<Modal>({
  name: null,
  attrs: {}, // current modal's props and modalLayout props
})

export const useModal = () => {
  const open = (name: Modals, attrs: Dictionary<any> = {}) => {
    if (name) {
      state.value.name = name
      state.value.attrs = attrs
    }
  }

  const close = () => {
    state.value.name = null
    state.value.attrs = {}
  }

  return {
    state,

    open,
    close,
  }
}
