import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0d8bb4c3 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _8bbf45f4 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _2e3e4d79 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _7d30b9b5 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _518bab75 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _74cec1c6 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _59ff315e = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _cc7d247e = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _7c167b1d = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _53603a94 = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _10341ee0 = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _dd4e39e0 = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _798d03f0 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _fb75aa06 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _86a733c0 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _104856ba = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _a26aa89a = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _e0c9d586 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _2c5032b8 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _03497997 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _aa48e0c0 = () => interopDefault(import('../pages/checkout/transsmart.vue' /* webpackChunkName: "pages/checkout/transsmart" */))
const _3c6772ce = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _041df656 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _bdfe84a2 = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _380138de = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _20a474d3 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _4ea3627c = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _8ba6c540 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _8a7cd8b8 = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _49276d54 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _91f34e3c = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _3e78b24a = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _58413e70 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _7e1d4180 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _31ca48af = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _b2adb774 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _4aa6b295 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _58c1f76d = () => interopDefault(import('../pages/review/_slug.vue' /* webpackChunkName: "pages/review/_slug" */))
const _4cf3c1bd = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _3547c314 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _175389b1 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _d9f65152 = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _59c7937d = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _19490068 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _62100f16 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _0d8bb4c3,
    children: [{
      path: "",
      component: _8bbf45f4,
      name: "account___nl"
    }, {
      path: "details",
      component: _2e3e4d79,
      name: "account-details___nl"
    }, {
      path: "newsletter",
      component: _7d30b9b5,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _518bab75,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _74cec1c6,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _59ff315e,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _cc7d247e,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _7c167b1d,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _53603a94,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _10341ee0,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _dd4e39e0,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _798d03f0,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/cart",
    component: _fb75aa06,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _86a733c0,
    children: [{
      path: "",
      component: _104856ba,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _a26aa89a,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _e0c9d586,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _2c5032b8,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _03497997,
      name: "checkout-payment___nl"
    }, {
      path: "transsmart",
      component: _aa48e0c0,
      name: "checkout-transsmart___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _3c6772ce,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _041df656,
    name: "checkout-success___nl"
  }, {
    path: "/faq",
    component: _bdfe84a2,
    name: "faq___nl"
  }, {
    path: "/forgot-password",
    component: _380138de,
    name: "forgot-password___nl"
  }, {
    path: "/icon",
    component: _20a474d3,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _4ea3627c,
    name: "login___nl"
  }, {
    path: "/merken",
    component: _8ba6c540,
    name: "brand___nl"
  }, {
    path: "/nieuws",
    component: _8a7cd8b8,
    name: "blog-category___nl"
  }, {
    path: "/vestigingen",
    component: _49276d54,
    name: "store-lister___nl"
  }, {
    path: "/blocks/demo",
    component: _91f34e3c,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _3e78b24a,
    name: "forgot-password-reset___nl"
  }, {
    path: "/login/guest",
    component: _58413e70,
    name: "login-guest___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _7e1d4180,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/nieuws/detail/:slug?",
    component: _31ca48af,
    name: "blog-detail-slug___nl"
  }, {
    path: "/product/detail/:slug?",
    component: _b2adb774,
    name: "product-detail-slug___nl"
  }, {
    path: "/product/lister/*",
    component: _4aa6b295,
    name: "product-lister-all___nl"
  }, {
    path: "/review/:slug?",
    component: _58c1f76d,
    name: "review-slug___nl"
  }, {
    path: "/search/:slug?",
    component: _4cf3c1bd,
    name: "search-slug___nl"
  }, {
    path: "/winkel/:slug?",
    component: _3547c314,
    name: "store-detail-slug___nl"
  }, {
    path: "/nieuws/*",
    component: _175389b1,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _d9f65152,
    name: "blog-tag-all___nl"
  }, {
    path: "/merken/*",
    component: _59c7937d,
    name: "brand-lister-all___nl"
  }, {
    path: "/",
    component: _19490068,
    name: "index___nl"
  }, {
    path: "/*",
    component: _62100f16,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
